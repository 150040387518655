import React from 'react';
import { HeadlineStyled1, HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import styled from 'styled-components';
import AppIcon from 'components/icons/AppIcon';
import { useNavigate } from 'react-router-dom';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { dateFormatter } from 'utils/date';
import { meetingMode } from 'utils/meetingMode';

const PageTitle = ({ meeting, otherText, hasBackTo, subText, mainText = 'Assemblée Générale', classes, showMode }) => {
  const history = useNavigate();

  return (
    <PageTitleStyled className={'common-page-header--styled d-flex'}>
      {hasBackTo?.length && <AppIcon name={'arrow'} onClick={() => history(hasBackTo)} classes={'back-to-btn'} />}
      <div className={'page-title-content'}>
        <HeadlineStyled1 className='mb-0' $weight='medium'>
          {mainText} <br />
          {meeting !== undefined && 'Copropriété ' + meeting?.jointOwnership?.name}
        </HeadlineStyled1>

        <div className='d-flex page-title-subtext mb-1 mt-3'>
          <HeadlineStyled3 $weight='bold' className='mb-0'>
            <span>{dateFormatter(meeting?.date)}</span>
            {!!subText && <span> {subText}</span>}
            {!!otherText && <span> - {otherText}</span>}
            {!!showMode && <span> - {meetingMode[meeting?.mode]}</span>}
          </HeadlineStyled3>
        </div>
      </div>
    </PageTitleStyled>
  );
};

const PageTitleStyled = styled.header`
  margin-bottom: 50px;
  padding-top: 25px;

  flex-direction: column-reverse;
  align-items: flex-end;

  .back-to-btn {
    transform: rotate(90deg);
    position: relative;
    top: 5px;
    cursor: pointer;
    left: 0;
    transition: 0.2s ease-in-out left;
    &:hover {
      left: -2px;
    }
  }

  .headline-1 {
    margin-bottom: 40px;
    text-align: right;
    font-size: 1.25rem;
  }

  .page-title-subtext {
    justify-content: flex-end;
  }

  justify-content: flex-end;

  @media only screen and ${breakpoint.device.lg} {
    justify-content: flex-start;
    flex-direction: row;
    align-items: initial;

    .back-to-btn {
      margin-right: 30px;
    }

    .headline-1 {
      text-align: left;
      font-size: 2.125rem;
      padding: 0;
    }

    .page-title-subtext {
      justify-content: flex-start;
    }
  }
`;

export default PageTitle;
