import React from 'react';
import { NavLinkToStyled } from 'components/styled/buttons/CustomLinkToStyled';

const NavLinks = ({ device = 'desktop', onSetShowNav }) => {
  return (
    <ul className={`${device}-nav__links nav__links`}>
      <li className={`${device}-nav__link-item nav__link-item`}>
        <NavLinkToStyled
          to='/'
          iconName='dashboard'
          isNavLink
          click={() => {
            if (onSetShowNav) onSetShowNav((prevState) => !prevState);
          }}
        >
          Accueil
        </NavLinkToStyled>
      </li>

      <li className={`${device}-nav__link-item nav__link-item`}>
        <NavLinkToStyled
          to='/meetings'
          iconName='assemblees'
          isNavLink
          click={() => {
            if (onSetShowNav) onSetShowNav((prevState) => !prevState);
          }}
        >
          Assemblées
        </NavLinkToStyled>
      </li>

      <li className={`${device}-nav__link-item nav__link-item`}>
        <NavLinkToStyled
          to='/documents'
          iconName='folder'
          isNavLink
          click={() => {
            if (onSetShowNav) onSetShowNav((prevState) => !prevState);
          }}
        >
          Documents
        </NavLinkToStyled>
      </li>
    </ul>
  );
};

export default NavLinks;
