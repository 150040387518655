import React, { useEffect, useState } from 'react';
import { useGetAllMeetingConvocationsQuery } from 'api/services/meetingApi';

export const useMeetingDocs = (meetingData) => {
  const [filter, setFilter] = useState({
    page: 1,
    perPage: 10,
    header: false,
    owner: null,
    meeting: null
  });
  const { data: convocationsData } = useGetAllMeetingConvocationsQuery(filter, {
    skip: !filter.owner
  });

  useEffect(() => {
    if (meetingData) {
      setFilter((prev) => ({
        ...prev,
        owner: meetingData?.event?.currentParticipant?.owner?.id,
        meeting: meetingData.id
      }));
    }
  }, [meetingData]);

  const allDocs = () => {
    let meetingItemsDocs = [];

    if (meetingData) {
      // first we collect the documents at the meeting level
      meetingItemsDocs = [...meetingData?.documents];

      for (const item of meetingData?.items) {
        if (item.documents.length) {
          meetingItemsDocs = [...meetingItemsDocs, ...item?.documents];
        }

        if (item.options.length) {
          for (const itemOption of item.options) {
            if (itemOption.documents.length) {
              meetingItemsDocs = [...meetingItemsDocs, ...itemOption?.documents];
            }
          }
        }

        if (item?.compareTable && !meetingItemsDocs.some((it) => it.id === item?.compareTable?.id)) {
          meetingItemsDocs.push(item?.compareTable);
        }
      }

      if (convocationsData) {
        meetingItemsDocs = [...meetingItemsDocs, ...convocationsData[0]?.documents];
      }
    }

    return meetingItemsDocs;
  };

  return [...allDocs()];
};
