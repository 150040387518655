import React from 'react';
import styled from 'styled-components';
import useGetOwnerBundles from 'hooks/useGetOwnerBundles';
import { PillRef } from 'components/styled/pills/Pill';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { Loader } from 'components/loader';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import ListHeadStyled from 'components/styled/blocks/ListHeadStyled';
import { BlockSection } from 'components/styled/blocks/BlockSection';

const UserBundlesList = ({ jointOwnerships, meetings }) => {
  return (
    <BlockSection>
      <MainSubContainer>
        <h3 className='block-section__title'>Mes lots</h3>
        <ListHeadStyled className='container-fluid inner-padding'>
          <div className='row'>
            <div className='col-4'>Nature</div>
            <div className='col-4'>Numéro cadastral</div>
            <div className='col-4'>Millèmes</div>
          </div>
        </ListHeadStyled>
        {jointOwnerships.map((jo) => (
          <UserBundleGroup
            id={jo.id}
            key={`userbundle-${jo.id}`}
            meetingData={meetings?.find((meeting) => meeting.joint_ownership_id === jo.id)}
          />
        ))}
      </MainSubContainer>
    </BlockSection>
  );
};

const UserBundleGroup = ({ id, meetingData }) => {
  const {
    ownerships: { bundles },
    isLoading
  } = useGetOwnerBundles(id);

  if (isLoading) return <Loader />;

  return (
    <UserBundleStyled>
      <header className={'bundle-item__header'}>
        <PillRef className={'pill-ref-styled--large'}>{meetingData?.joint_ownership_code}</PillRef>
        <div className='bundle-item__title'>
          <h3>{meetingData?.joint_ownership_name}</h3>
          <span>{meetingData?.joint_ownership_address}</span>
        </div>
      </header>

      {bundles?.length > 0 && (
        <section className={'bundle-item__body container'}>
          {bundles.map((bundle) => (
            <BundleRow
              className={'row text-center'}
              identifier={bundle.identifier}
              category={bundle.category}
              key={bundle.id}
              thousandths={bundle.thousandths}
            />
          ))}
        </section>
      )}
    </UserBundleStyled>
  );
};

export default UserBundlesList;

const BundleRow = ({ identifier, category, thousandths }) => {
  return (
    <div className={'bundle-row-styled row'}>
      <p className={'bundle-row__col bundle-row__col-1 col-5 col-lg-4'}>{category}</p>
      <p className={'bundle-row__col bundle-row__col-2 col-3 col-lg-4'}>{identifier}</p>
      <p className={'bundle-row__col bundle-row__col-3 col-4 col-lg-4'}>{thousandths}</p>
    </div>
  );
};

const UserBundleStyled = styled.div`
  .bundle-item__header {
    display: flex;
    background-color: #f2f7f9;
    align-items: center;
    border-radius: ${CSS_GUIDE.borderRadius} ${CSS_GUIDE.borderRadius} 0 0;
    padding: 15px 30px;

    .bundle-item__title {
      margin-left: 20px;
    }

    h3 {
      color: ${CSS_GUIDE.colors.primary.hex};
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  .bundle-item__body {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 0 0 ${CSS_GUIDE.borderRadius} ${CSS_GUIDE.borderRadius};
  }

  .bundle-row-styled {
    display: flex;
    padding: 20px 0;
  }

  .bundle-row__col {
    margin-bottom: 0;

    &.bundle-row__col-1 {
      padding-left: 0;
    }
  }

  .bundle-row__col-1,
  .bundle-row__col-3 {
    font-weight: 500;
    color: ${CSS_GUIDE.colors.primary.hex};
  }

  .bundle-row__col-1 {
    font-size: 1rem;
  }

  .bundle-row__col-3 {
    font-size: 1.125rem;
  }

  .bundle-row-styled + .bundle-row-styled {
    border-top: 1px solid ${CSS_GUIDE.colors.primary.hex};
  }

  @media only screen and ${breakpoint.device.lg} {
    .bundle-item__body {
      padding: 30px 60px;
    }

    .bundle-item__header {
      padding: 15px 60px;
    }
  }
`;
