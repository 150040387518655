import React from 'react';
import styled from 'styled-components';
import AppIcon from 'components/icons/AppIcon';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import Card from 'components/styled/wrappers/Card';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoints from 'components/styled/breakpoints/breakpoints';
import locationFormatter from 'utils/location';
import { dateFormatter } from 'utils/date';

const MeetingInfoMonoBlock = ({ meeting }) => {
  return (
    <Card>
      <MeetingInfoMonoBlockStyled className={'meeting-info-block--styled'}>
        <div className={'meeting-info-block meeting-info-block--top'}>
          <div className={'meeting-info-block__icon'}>
            <AppIcon name={'clock'} classes={`svg-date`} />
          </div>

          <div className='meeting-info-block__detail'>
            <HeadlineStyled3 $weight={'bold'}>Date</HeadlineStyled3>
            <p>
              <span>{dateFormatter(meeting?.date, 'full')}</span>, à <span>{dateFormatter(meeting?.date, 'hour')}</span>
            </p>
          </div>
        </div>

        {meeting?.mode !== 2 && !!meeting?.location && (
          <div className={'meeting-info-block meeting-info-block--bottom'}>
            <div className={'meeting-info-block__icon'}>
              <AppIcon name={'location'} classes={`svg-location`} />
            </div>

            <div className='meeting-info-block__detail'>
              <HeadlineStyled3 $weight={'bold'}>Lieu</HeadlineStyled3>
              <div className='location-infos'>
                {locationFormatter(meeting)}
                {/*<AppIcon name={'gps'} classes={'svg-gps'} />*/} {/*// todo display after creating gps link*/}
              </div>
            </div>
          </div>
        )}

        {/*this block is hidden from now on*/}
        {/*meeting hybride or by videoconference and status ongoing*/}
        {/*{(meeting?.mode === 1 || meeting?.mode === 2) && meeting?.status?.id === 3 && (
          <div className={'meeting-info-block meeting-info-block--bottom'}>
            <div className={'meeting-info-block__icon'}>Z</div>

            <div className='meeting-info-block__detail'>
              <HeadlineStyled3 $weight={'bold'}>Visioconférence</HeadlineStyled3>
              <div>
                <a href={`https://zoom.us/j/${meeting?.event?.zoomId}`} target='_blank' rel='noreferrer'>
                  Zoom meeting
                </a>{' '}
                (ID : {meeting?.event?.zoomId}) <br />
                Mot de passe : {meeting?.jointOwnership?.code}
              </div>
            </div>
          </div>
        )}*/}
      </MeetingInfoMonoBlockStyled>
    </Card>
  );
};

export default MeetingInfoMonoBlock;

const MeetingInfoMonoBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  color: ${CSS_GUIDE.colors.primary.hex};

  .meeting-info-block {
    display: flex;
    padding: 30px 0;

    &--bottom {
      border-top: 1px solid ${CSS_GUIDE.colors.primary.hex};
    }
  }

  .meeting-info-block__icon {
    background-color: ${CSS_GUIDE.colors.primary.rgba};
    width: 30px;
    height: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    margin-right: 20px;
    flex-shrink: 0;
  }

  .aea-svg {
    fill: currentColor;
    width: 25px;
    height: 25px;
  }

  address {
    margin-bottom: 0;
  }

  .meeting-info-block__detail p {
    margin: 0;
    font-weight: 500;
  }

  .location-infos {
    display: flex;
    align-items: center;
  }

  .location-details {
    display: flex;
    flex-direction: column;
  }

  .svg-gps {
    width: 37px;
    height: 37px;
    margin-left: 40px;
  }

  strong {
    font-weight: 700;
    font-size: 1.1rem;
  }

  /*  @media only screen and ${breakpoints.device.lg} {

  }*/
`;

MeetingInfoMonoBlockStyled.displayName = 'MeetingInfoMonoBlockStyled';
