import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const ListHeadStyled = styled.div.attrs({
  className: 'list-head-style'
})`
  border-radius: ${CSS_GUIDE.borderRadius};
  background-color: ${CSS_GUIDE.backgrounds.tertiary};
  color: ${CSS_GUIDE.colors.primary.hex};
  font-weight: 700;

  margin-bottom: 15px;

  &.inner-padding {
    padding: 10px 30px;
  }

  .row {
    align-items: center;
  }

  @media only screen and ${breakpoint.device.lg} {
    height: 60px;
    line-height: 60px;
    &.inner-padding {
      padding: 0 60px;
    }
  }
`;

export default ListHeadStyled;
