import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const TogglePassWrapperStyled = styled.div`
  position: relative;
  margin-top: ${CSS_GUIDE.space.vertical.input}px;
  .pass-wrapper__input {
    padding-right: 40px;
  }

  .pass-wrapper__eye {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export default TogglePassWrapperStyled;
TogglePassWrapperStyled.displayName = 'TogglePassWrapperStyled';
