import React from 'react';
import AppIcon from 'components/icons/AppIcon';
import { TextWithIconStyled } from 'components/styled/texts/TextWithIconStyled';

const TextWithIcon = ({ iconName = 'Location', children, otherClassName }) => {
  return (
    <TextWithIconStyled className={otherClassName}>
      <AppIcon name={iconName} />
      <div>{children}</div>
    </TextWithIconStyled>
  );
};

export default TextWithIcon;
