import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from '../breakpoints/breakpoints';

const Pill = styled.div`
  border-radius: ${CSS_GUIDE.borderRadius};
  display: inline-block;
  min-height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 0 10px;
  //padding: ${({ large }) => (large ? '0 20px;' : '0 10px;')};
`;

export const PillRef = styled(Pill).attrs({
  className: 'pill-ref-styled'
})`
  background-color: ${CSS_GUIDE.colors.orange.hex};
  color: #ffffff;
  font-size: ${({ large }) => (large ? '1rem;' : '0.875rem;')};
  position: relative;

  &.pill-ref-styled--large {
    padding: 10px;
    font-weight: 500;
  }

  ${({ pillType }) => {
    if (pillType === 'components') {
      return `
      left: -20px;
      bottom: -17px;
      `;
    }
    if (pillType === 'residence') {
      return `
      top: -3px;
      left: -25px;
      `;
    }
  }} @media only screen and ${breakpoint.device.lg} {
    ${({ pillType }) => {
      if (pillType === 'components') {
        return `
      left: 0;
      bottom: 6px;
      `;
      }
      if (pillType === 'residence') {
        return `
      top: -3px;
      left: -25px;
      `;
      }
    }}
  }
`;

export const PillInfo = styled(Pill)`
  background-color: rgba(${CSS_GUIDE.colors.primary.rgb}, 0.1);
  color: ${CSS_GUIDE.colors.primary.hex};
  font-size: 0.875rem;
  ${({ large }) => {
    if (large) {
      return `
      height: 45px;
      line-height: 46px;
      // padding: 0 11px;
    `;
    }
  }}
`;
