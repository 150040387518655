import React from 'react';
import LoginPageGlobal from 'components/styled/wrappers/login/LoginPageGlobal';
import PasswordResetStyled from 'components/styled/wrappers/login/PasswordResetStyled';
import PasswordResetForm from 'pages/passwordRecovery/PasswordResetForm';
import { HeadlineStyled2 } from 'components/styled/headings/HeadlineStyled';

const PasswordReset = () => {
  const renderPassReset = (
    <div className='container password-reset-page__container'>
      <header className='password-reset-page__header password-header'>
        <HeadlineStyled2 $weight={'bold'}>Réinitialiser votre mot de passe</HeadlineStyled2>
        <p>
          Celui-ci doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère
          spécial.
        </p>
      </header>
      <PasswordResetForm />
    </div>
  );

  return (
    <LoginPageGlobal classes='login-page-wrapper--pass-process'>
      <PasswordResetStyled>{renderPassReset}</PasswordResetStyled>
    </LoginPageGlobal>
  );
};

export default PasswordReset;
