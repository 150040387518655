import React, { useEffect } from 'react';
import ModalComponent from 'components/modals/ModalComponent';
import { Button } from 'components/styled/buttons/ButtonStyled';
import { useConfirmConvocationMutation, useSigneForEventMutation } from 'api/services/meetingEventParticipantApi';
import { dateFormatter } from 'utils/date';
import { addDays, differenceInDays } from 'date-fns';
import { toast } from 'react-toastify';

const ModalConvocation = ({ modalProps, confirmations }) => {
  const [rsvpConfirmation, { isSuccess }] = useSigneForEventMutation();
  const [confirmConvocation] = useConfirmConvocationMutation();

  const confirmation = confirmations[0];
  const convocationObj = confirmation.convocations[0];

  useEffect(() => {
    if (isSuccess) {
      toast('Merci, votre confirmation a bien été prise en compte', {
        type: 'success'
      });
    }
  }, [isSuccess]);

  const remainingTime = () => {
    if (!convocationObj?.sendingDate) return;

    const today = new Date();
    const convocationSendingDate = new Date(convocationObj.sendingDate?.date);
    const limitDate = addDays(convocationSendingDate, 6 + 1);
    const daysDifference = differenceInDays(today, convocationSendingDate);

    if (daysDifference + 1 > 6) return;
    return differenceInDays(limitDate, today);
  };

  const handleClick = () => {
    rsvpConfirmation({
      id: confirmation.participantId,
      rsvpConfirmationDate: new Date()
    });

    confirmation?.convocations?.map((convocation) => {
      confirmConvocation({
        id: convocation?.id,
        data: {
          confirmation: true,
          confirmationDate: new Date()
        }
      });
    });

    modalProps.closeModal();
  };

  return (
    <ModalComponent showCloseBtn={false} modalProps={modalProps} otherClass={'Modal-convocation Modal-special-border'}>
      <header className='inner-convocation__header'>
        <h3>Convocation AG</h3>
        <p>
          <strong>
            Vous avez reçu votre convocation à la prochaine assemblée générale de la résidence{' '}
            {confirmation.jointOwnershipName}, <br />
            qui aura lieu le {dateFormatter(confirmation?.date)} à {dateFormatter(confirmation?.date, 'hour')}.
            <br /> Pensez à en confirmer la bonne réception.
          </strong>
        </p>
      </header>

      <hr className='Modal-divider d-none d-lg-block' />

      <main className='inner-convocation__main'>
        <p>
          Je confirme avoir reçu la convocation et avoir pris connaissance que suivant la loi du 16 mai 1975, aucun
          point ne pourra être voté qui ne figure pas à l’ordre du jour. J'ai 6 jours après l'envoi de cette convocation
          pour notifier le syndic des points supplémentaires que celui-ci doit notifier aux copropriétaires au minimum 5
          jours avant l’assemblée générale.
        </p>

        {remainingTime() ? (
          <p>Actuellement il reste encore {remainingTime()} jour(s) pour envoyer des points supplémentaires.</p>
        ) : (
          <p>Il n'est plus possible d'envoyer des points supplémentaires</p>
        )}

        <p>Si je ne confirme pas cette notification, un courrier recommandé sera envoyé avec la convocation.</p>
      </main>
      <footer>
        <Button onClick={handleClick}>Je confirme la réception</Button>
      </footer>
    </ModalComponent>
  );
};

export default ModalConvocation;
