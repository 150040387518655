import { apiCreator } from 'api/baseApi';
import {
  MEETING_BY_ID,
  MEETING_CONVOCATIONS,
  MEETING_ITEM_BY_ID,
  MEETING_ITEM_COMMENT,
  MEETING_ITEM_COMMENT_BY_ID,
  MEETINGS,
  PENDING_CONFIRMATIONS
} from 'constants/api.constants';

const endPoints = (build) => ({
  getAllMeetings: build.query({
    query: () => ({
      url: MEETINGS
    }),
    transformResponse: (response) => response.results
  }),

  getPendingConfirmations: build.query({
    query: () => ({
      url: PENDING_CONFIRMATIONS
    })
  }),

  getMeetingById: build.query({
    query: (id) => ({
      url: MEETING_BY_ID(id)
    })
  }),

  getMeetingItemById: build.query({
    query: (id) => ({
      url: MEETING_ITEM_BY_ID(id)
    })
  }),

  getMeetingItemComments: build.query({
    query: ({ page, perPage, item, participant }) => ({
      params: { page, perPage, item, participant },
      url: MEETING_ITEM_COMMENT
    })
  }),

  addMeetingItemComment: build.mutation({
    query: (data) => ({
      url: MEETING_ITEM_COMMENT,
      method: 'POST',
      data
    })
  }),

  updateMeetingItemComment: build.mutation({
    query: ({ id, data }) => ({
      url: MEETING_ITEM_COMMENT_BY_ID(id),
      method: 'PATCH',
      data
    })
  }),

  deleteMeetingItemComment: build.mutation({
    query: (id) => ({
      url: MEETING_ITEM_COMMENT_BY_ID(id),
      method: 'DELETE'
    })
  }),

  getAllMeetingConvocations: build.query({
    query: ({ page, perPage, fullName, header, meeting, owner }) => ({
      params: {
        page,
        perPage,
        fullName,
        meeting,
        'owners.id': owner
      },
      url: MEETING_CONVOCATIONS,
      header
    })
  })
});

export const meetingApi = apiCreator({
  reducerPath: 'meetingApi',
  endPointsConfigFn: endPoints
});

export const {
  useGetMeetingByIdQuery,
  useGetAllMeetingsQuery,
  useGetPendingConfirmationsQuery,
  useGetMeetingItemByIdQuery,
  useGetMeetingItemCommentsQuery,
  useAddMeetingItemCommentMutation,
  useUpdateMeetingItemCommentMutation,
  useDeleteMeetingItemCommentMutation,
  useGetAllMeetingConvocationsQuery
} = meetingApi;
