import React from 'react';
import styled from 'styled-components';
import { ReactComponent as NoAGToCome } from 'assets/images/NoAGToCome.svg';
import { CSS_GUIDE } from 'components/styled/constants';

const NoMeetingToCome = () => {
  return (
    <NoMeetingStyled>
      <NoAGToCome />
      <p>Aucune assemblée à venir</p>
    </NoMeetingStyled>
  );
};

const NoMeetingStyled = styled.div`
  text-align: center;
  color: ${CSS_GUIDE.colors.primary.hex};

  p {
    margin-top: 15px;
    color: ${CSS_GUIDE.colors.primary.hex};
  }
`;
NoMeetingStyled.displayName = 'NoMeetingStyled';

export default NoMeetingToCome;
