import React from 'react';
import styled from 'styled-components';
import Card, { CardInner } from 'components/styled/wrappers/Card';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import AppIcon from 'components/icons/AppIcon';
import { LinkButtonStyled } from 'components/styled/buttons/ButtonStyled';
import breakpoints from 'components/styled/breakpoints/breakpoints';

const MeetingActionBlock = ({ title, iconName, text, linkTo = '/', disabled, meetingStatus, isBtnLight = false }) => {
  return (
    <Card>
      <CardInner>
        <MeetingActionBlockStyled>
          <HeadlineStyled3 $weight={'bold'}>{title}</HeadlineStyled3>

          <div className={`meeting-action-block__content ${!!iconName ? 'has-icon' : ''}`}>
            {!!iconName && <AppIcon name={iconName} classes={`svg-${iconName}`} />}
            <p className={'meeting-action-block__text'}>{text}</p>
          </div>

          {!!iconName && meetingStatus === 3 ? (
            <LinkButtonStyled disabled={disabled} to={linkTo} $light={isBtnLight}>
              Voter maintenant
            </LinkButtonStyled>
          ) : (
            <LinkButtonStyled to={linkTo} $light={isBtnLight}>
              Voir les détails
            </LinkButtonStyled>
          )}
        </MeetingActionBlockStyled>
      </CardInner>
    </Card>
  );
};

export default MeetingActionBlock;

const MeetingActionBlockStyled = styled.div`
  .meeting-action-block__content {
    display: flex;
    margin: 40px 0 20px;
    &.has-icon {
      align-items: center;
      p {
        margin-bottom: 0;
      }
    }
  }

  .aea-svg + .meeting-action-block__text {
    margin-left: 30px;
  }

  @media only screen and ${breakpoints.device.xl} {
    .svg-vote-box {
      max-width: 75px;
      max-height: 75px;
    }
  }
`;
