import React from 'react';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import DocumentDisplay from 'components/commun/documentDisplay';
import { useMeetingDocs } from '../useMeetingDocs';
import { downloadAnyDocument } from 'api/fileManagementDownload';
import { MEETING_INVITATION_DOWNLOAD_BY_ID } from 'constants/api.constants';
import { dateFormatter } from 'utils/date';
import DownloadButton from 'components/styled/buttons/DownloadButton';

const MeetingDocsList = ({ meeting }) => {
  const allMeetingsDocs = useMeetingDocs(meeting);
  const owner = meeting?.event?.currentParticipant?.owner;

  const handleDownload = async (action) => {
    const fileName = `${meeting?.jointOwnership?.code}-${action}-AG_${dateFormatter(meeting?.date, 'api')}.pdf`;

    await downloadAnyDocument({
      url: MEETING_INVITATION_DOWNLOAD_BY_ID(meeting?.id),
      data: {
        ownerObject: {
          fullName: owner?.fullName,
          address: owner?.address
        },
        action
      },
      method: 'POST',
      fileName
    });
  };

  return (
    <>
      <HeadlineStyled3 $weight={'bold'}>Annexes de la convocation</HeadlineStyled3>
      {allMeetingsDocs?.length > 0 ? (
        <>
          <ul className={'meeting-detail__doc-list row'}>
            {allMeetingsDocs.map((doc, index) => (
              <li
                className={`meeting-detail__doc-list-item ${allMeetingsDocs?.length > 4 ? 'col-6' : ''}`}
                key={`${doc.id}-${index}`}
              >
                <DocumentDisplay id={doc.id} name={doc.name} />
              </li>
            ))}
          </ul>

          {/* TODO: code to download the invitation
           {meeting?.status?.id === 3 && (
            <div className={'row'}>
              <div className={'col-lg-6 download-btn-item'}>
                <DownloadButton
                  onClick={() => {
                    handleDownload('vote');
                  }}
                >
                  <span>Bulletin de vote</span>
                </DownloadButton>
              </div>

              <div className={'col-lg-6 download-btn-item'}>
                <DownloadButton
                  onClick={() => {
                    handleDownload('procuration');
                  }}
                >
                  <span>Procuration</span>
                </DownloadButton>
              </div>
            </div>
          )} */}
        </>
      ) : (
        <p>Cette section ne comporte pas de document</p>
      )}
    </>
  );
};

export default MeetingDocsList;
