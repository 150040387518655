import styled from 'styled-components';
import Card from 'components/styled/wrappers/Card';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { CSS_GUIDE } from '../../constants';

const LoginPageCard = styled(Card).attrs({
  className: 'login-page__card'
})`
  margin: 0;
  padding-top: 90px;
  top: 150px;
  width: 100%;
  text-align: center;
  height: auto;
  height: fit-content;
  min-height: 300px;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background: #fb6854;
    border-radius: 100px;
  }

  &::after {
    width: 146px;
    height: 79px;
    right: -100px;
    bottom: -21px;
  }

  &::before {
    width: 208px;
    height: 115px;
    left: -103px;
    top: -26px;
  }

  .login-page-wrapper--pass-process & {
    background: transparent;
    box-shadow: none;
    position: static;
  }

  .text-field-inline-styled {
    color: #ffffff;
  }

  .login-form {
    position: relative;
  }

  @media only screen and ${breakpoint.device.lg} {
    //width: 500px;
    top: auto;
    width: 500px;
    height: 650px;
    padding: 90px 70px 15px;

    .login-page-wrapper--pass-process & {
      background: #ffffff;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      position: relative;
    }

    &::before,
    &::after {
      height: 80px;
      width: 225px;
    }

    &::before {
      left: -50px;
      top: -25px;
    }

    &::after {
      right: -45px;
      bottom: -28px;
    }

    .text-field-inline-styled {
      color: ${CSS_GUIDE.colors.darkGrey2};
      border-bottom-color: ${CSS_GUIDE.colors.midGrey2.hex};
    }
  }
`;

export default LoginPageCard;
LoginPageCard.displayName = 'LoginPageCard';
