import { useState } from 'react';
import parseJwt from '../utils/jwtParser';

export default function useProvideAuth() {
    const [user, setUser] = useState(sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null);

    const [authToken, setAuthToken] = useState(sessionStorage.getItem('token'));

    const AUTHORIZED_ROLES = ['ROLE_OWNER', 'ROLE_TENANT', 'ROLE_ADMIN', 'ROLE_ACCOUNTANT', 'ROLE_ADMIN_STAFF', 'ROLE_APPRENTICE'];

    const setTokens = (token) => {
        sessionStorage.setItem('token', token);
        setAuthToken(token);
    };

    const setUserInfos = (data) => {
        const userObj = { firstName: data.firstName, lastName: data.lastName, id: data.id, ownerId: data.ownerId };
        setUser(userObj);
        sessionStorage.setItem('user', JSON.stringify(userObj));
    };

    const getUser = () => {
        const userData = sessionStorage.getItem('user');
        return JSON.parse(userData);
    };

    const signIn = ({ data, token }) => {
        setUserInfos(data);
        setTokens(token);
    };

    const signOut = () => {
        setUser(null);
        setTokens(null);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('jointOwnerships');
    };

    const checkRoles = () => {
        const payload = parseJwt(authToken);
        return payload?.roles?.some(r => AUTHORIZED_ROLES.includes(r));
    };

    // Return the user object and auth methods
    return {
        user, signIn, signOut, authToken, setAuthToken: setTokens, getUser, hasRoles: checkRoles()
    };
}
