import React from 'react';
import pdfIcon from './pdfIcon.svg';
import { downloadAnyDocument } from 'api/fileManagementDownload';
import AppIcon from 'components/icons/AppIcon';
import ReactTooltip from 'react-tooltip';

const getShortVersion = (text, maxLength = 20) => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength - 3) + '...';
  }
  return text;
};

const DocumentDisplay = ({ id, name, price = '', classes = '', altDisplay }) => {
  const downloadDoc = (idDoc, fileName) => {
    downloadAnyDocument({
      url: `/documents/${idDoc}/download`,
      fileName
    });
  };

  return (
    <div className={`fileDisplay ${classes} ${altDisplay ? 'altDisplay' : ''}`} onClick={() => downloadDoc(id, name)}>
      {altDisplay ? <AppIcon name={'eyes'} /> : <img src={pdfIcon} className='me-2' />}
      <div className={'fileDisplayText'}>
        {altDisplay ? (
          <span>Voir le tableau comparatif</span>
        ) : (
          <>
            <h1 data-tip={`${name?.length >= 30 ? name : ''}`}>
              <span>{getShortVersion(name, 30)}</span>
            </h1>
            {name?.length >= 30 && <ReactTooltip place='top' type='dark' effect='float' />}
          </>
        )}
        {price && <span>{price} €</span>}
      </div>
    </div>
  );
};

export default DocumentDisplay;
