import { useUpdatePVListMutation } from 'api/services/meetingStaticDataApi';
import ModalBase from 'components/modals/ModalBase';
import { Button } from 'components/styled/buttons/ButtonStyled';

const ModalPv = ({ isOpen, closeModal, pvData }) => {
  const [updatePVList] = useUpdatePVListMutation();

  const handleClick = async () => {
    pvData.map(async (pv) => {
      await updatePVList({
        id: pv.id,
        data: {
          confirmationDate: new Date()
        }
      });
    });
    await closeModal();
  };
  return (
    <ModalBase
      showCloseBtn={false}
      isOpen={isOpen}
      closeModal={closeModal}
      isLocked={true}
      otherClass={'Modal-convocation Modal-special-border'}
    >
      <header className='inner-convocation__header'>
        <h3>Confirmation PV</h3>
      </header>

      <hr className='Modal-divider d-none d-lg-block' />

      <main className='inner-convocation__main'>
        <p>
          Je confirme avoir reçu le procès-verbal et avoir pris connaissance que suivant la loi du 16 mai 1975, j’ai 2
          mois après la notification de ce procès-verbal de le contester.
        </p>

        <p>Si je ne confirme pas cette notification, un courrier recommandé sera envoyé avec le PV.</p>
      </main>
      <footer>
        <Button onClick={handleClick}>Je confirme la réception</Button>
      </footer>
    </ModalBase>
  );
};

export default ModalPv;
