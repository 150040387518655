import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

export const TextWithIconStyled = styled.div.attrs({
  className: 'text-with-icon-styled'
})`
  display: flex;
  align-items: flex-start;
  color: ${CSS_GUIDE.colors.darkGrey2};
  svg {
    fill: ${CSS_GUIDE.colors.primary.hex};
    height: 30px;
    width: auto;
  }
  #svg-clock {
    height: 20px;
  }
  #svg-location {
    height: 25px;
  }
`;
