import React, { useEffect, useState } from 'react';
import useModal from 'hooks/useModal';
import { BlockSection } from 'components/styled/blocks/BlockSection';
import ContactBlock from './components/contact/ContactBlock';
import ModalConvocation from 'pages/dashboard/components/meetingItems/ModalConvocation';
import { useGetAllMeetingsQuery, useGetPendingConfirmationsQuery } from 'api/services/meetingApi';
import GenericPageWrapper from 'components/styled/wrappers/GenericPageWrapper';
import MainSubContainer from '../../components/styled/wrappers/MainSubContainer';
import MeetingItems from 'pages/dashboard/components/meetingItems';
import { HeadlineStyled1 } from 'components/styled/headings/HeadlineStyled';
import AppIcon from 'components/icons/AppIcon';
import { useAuthContext } from 'auth/ProvideAuth';
import { Loader } from 'components/loader';
import ModalPv from './components/meetingItems/ModalPv';
import { useGetPvListPendingConfirmationsQuery } from 'api/services/meetingStaticDataApi';
import DocumentFolders from 'pages/dashboard/components/documents';
import UserBundlesList from 'pages/dashboard/components/bundle';

const Dashboard = () => {
  const { openModal, ...modalProps } = useModal(true);
  const [isOpen, setIsOpen] = useState(false);
  const [userJointOwnerships, setUserJointOwnerships] = useState([]);

  const { data: confirmations, isLoading: isLoadingConfirmations } = useGetPendingConfirmationsQuery();
  const { data: pvData, isLoading: isLoadingPv } = useGetPvListPendingConfirmationsQuery();

  const { data: meetings, isLoading: isLoadingMeetings, error: errorsMeetings } = useGetAllMeetingsQuery();

  const { user } = useAuthContext();

  useEffect(() => {
    if (!isLoadingConfirmations && !isLoadingPv) {
      if (confirmations?.length > 0) {
        openModal();
      }

      if (pvData?.length > 0 && confirmations?.length === 0) {
        setIsOpen(true);
      }
    }
  }, [confirmations, isLoadingConfirmations, isLoadingPv, pvData]);

  useEffect(() => {
    if (!isLoadingMeetings && meetings?.length) {
      sessionStorage.setItem(
        'jointOwnerships',
        JSON.stringify(
          meetings.map((meeting) => ({
            joint_ownership_id: meeting.joint_ownership_id,
            joint_ownership_name: meeting.joint_ownership_name,
            user_ids: meeting.user_ids
          }))
        )
      );

      if (sessionStorage.getItem('jointOwnerships')) {
        const joIds = JSON.parse(sessionStorage.getItem('jointOwnerships'))?.reduce((idsArray, current) => {
          if (!idsArray.some((joObj) => joObj.id === current.joint_ownership_id)) {
            idsArray.push({ id: current.joint_ownership_id, name: current.joint_ownership_name });
          }
          return idsArray;
        }, []);
        setUserJointOwnerships(joIds);
      }
    }
  }, [meetings, isLoadingMeetings, user]);

  if (isLoadingConfirmations) {
    return <Loader width={200} />;
  }

  return (
    <GenericPageWrapper className='dashboard-page row'>
      <nav className='dashboard-logo-zone generic-page-wrapper__view-zone d-flex justify-content-center d-lg-none align-items-center'>
        <AppIcon classes={'dashboard-logo'} name={'logo-borderless'} />
        {/*<AppLogo classes={'dashboard-logo'} borderLess />*/}
      </nav>

      <BlockSection className='top-section'>
        {/*<MainSubContainer className='row'>*/}
        <MainSubContainer>
          <div className='container-lg block-section__container'>
            <div className='welcome-user-zone d-none d-lg-flex align-items-center'>
              {user && (
                <HeadlineStyled1 $weight='medium' className='welcome-user-title'>
                  Bonjour {user?.firstName} {user?.lastName}
                </HeadlineStyled1>
              )}
            </div>
          </div>
        </MainSubContainer>
      </BlockSection>

      {userJointOwnerships?.length > 0 && <UserBundlesList jointOwnerships={userJointOwnerships} meetings={meetings} />}

      <BlockSection className='mid-section'>
        <div className='row'>
          <div className='container-lg block-section__container'>
            {!isLoadingMeetings && !errorsMeetings && <MeetingItems meetings={meetings} />}
          </div>
        </div>
      </BlockSection>

      {userJointOwnerships?.length > 0 && <DocumentFolders jointOwnerships={userJointOwnerships} />}

      <BlockSection className='low-section block-section block-section-contacts'>
        <MainSubContainer className='row'>
          <div className='container-lg block-section__container'>
            <h3 className='block-section__title'>Mes contacts</h3>
            <p>Besoin d'aide ? Vous avez une question ? </p>
            <ul className='contacts-list row'>
              {[
                { name: 'Syndic', phone: '+352 26 68 45 01', email: 'info@aea.lu' },
                { name: 'Conseil syndical', phone: null, email: 'info@aea.lu' }
              ].map((contact) => (
                <li key={contact.name} className={'col-lg-6'}>
                  <ContactBlock name={contact.name} phone={contact.phone} email={contact.email} />
                </li>
              ))}
            </ul>
          </div>
        </MainSubContainer>
      </BlockSection>

      {confirmations && confirmations.length > 0 && (
        <ModalConvocation confirmations={confirmations} modalProps={modalProps} />
      )}
      {isOpen && <ModalPv isOpen={isOpen} closeModal={() => setIsOpen(false)} pvData={pvData} />}
    </GenericPageWrapper>
  );
};

export default Dashboard;
