import React from 'react';
import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const TextArea = ({ value, entity = 'form', placeholder, onChange, error, onBlur }) => {
  return (
    <TextAreaStyled error={error}>
      <textarea
        className={`${entity}-textarea ${error ? 'error' : ''}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />

      <div className={`textarea-ui-feedback ${error ? 'textarea-ui-feedback--error' : ''}`}>
        <span className={'text-count'}>{value?.length} / 500</span>
        <p className={'error-message'}>{error?.message}</p>
      </div>
    </TextAreaStyled>
  );
};

export default TextArea;

const TextAreaStyled = styled('div').attrs({
  className: 'textarea-styled'
})`
  position: relative;

  .error-message {
    display: ${(props) => (props?.error ? 'block' : 'none')};
    font-size: 0.875rem;
    color: ${CSS_GUIDE.colors.error};
  }

  .text-count {
    font-size: 0.75rem;
    color: ${CSS_GUIDE.colors.midGrey};
  }

  textarea {
    width: 100%;
    border: 1px solid ${CSS_GUIDE.colors.bgGrey};
    border-radius: ${CSS_GUIDE.borderRadius};
    min-height: 100px;
    padding: 20px 90px 20px 20px;
    display: flex;
    align-items: center;
    outline: none;

    &.error {
      border-color: ${CSS_GUIDE.colors.error};

      &::placeholder {
        color: ${CSS_GUIDE.colors.error};
      }
    }

    &::placeholder {
      display: block;
      top: 30px;
    }
  }

  .textarea-ui-feedback {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-top: 5px;

    &--error {
      color: ${CSS_GUIDE.colors.error};
      .text-count {
        color: inherit;
      }
    }
  }
`;

TextAreaStyled.displayName = 'TextAreaStyled';
