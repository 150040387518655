import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const ContactBlockStyled = styled.article.attrs({
  className: 'contact-block-styled'
})`
  flex-wrap: wrap;
  justify-content: space-between;
  &,
  .contact-block__left,
  .contact-block__right {
    display: flex;
    align-items: center;
  }

  height: 70px;

  .contact-infos {
    margin-left: 25px;
  }

  .contact-infos__name {
    font-weight: 700;
    color: ${CSS_GUIDE.colors.primary.hex};
    margin-bottom: 0;
  }

  .contact-infos__email,
  .contact-infos__tel {
    display: block;
  }

  .contact-infos__icon + .contact-infos__icon {
    margin-left: 20px;
  }

  .contact-infos__icon {
    svg {
      transition: transform 0.1s ease-in-out;
      &:hover {
        transform: rotate(15deg);
      }
    }
  }
`;

export default ContactBlockStyled;
