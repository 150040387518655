import React, { useEffect, useState } from 'react';
import ModalComponent from 'components/modals/ModalComponent';
import RadioButtonComponent from 'components/forms/RadioButtonComponent';
import { Button } from 'components/styled/buttons/ButtonStyled';
import SearchFieldStyled from 'components/styled/forms/SearchField';

const ModalProxy = ({ modalProps, type, participants, proxyChoiceRef, proxyChoiceRegister, setValue, watchProxy }) => {
  const [query, setQuery] = useState('');

  return (
    <ModalComponent
      showCloseBtn={true}
      modalProps={modalProps}
      otherClass={'Modal-proxy Modal-full-screen'}
      type={type}
    >
      <header className='inner-proxy__header modal-inner__header'>
        <h3>Sélectionnez un copropriétaire présent</h3>
      </header>

      <SearchFieldStyled
        placeholder={'Rechercher un copropriétaire'}
        onChange={(event) => setQuery(event.target.value)}
      />

      <main className='inner-participation__main modal-inner__main'>
        <div className='participation-form__owners'>
          {participants &&
            participants
              ?.filter(
                (participant) =>
                  participant?.owner &&
                  (participant?.owner?.person?.firstname + ' ' + participant?.owner?.person?.lastname)
                    .toLowerCase()
                    .includes(query.toLowerCase())
              )
              ?.map((participant) => {
                const { owner } = participant;
                return (
                  <RadioButtonComponent
                    key={participant.id}
                    labelText={
                      owner.type === 0 ? `${owner.person.firstname} ${owner.person.lastname}` : owner.company.name
                    }
                    value={participant.id}
                    ref={proxyChoiceRef}
                    {...proxyChoiceRegister}
                    reverse
                    isSelected={watchProxy == participant.id}
                    classes={'has-separator'}
                    callback={() => setValue('proxyEntity', null)}
                    pillInfoText={participant?.present ? 'Présence Confirmée' : null}
                  />
                );
              })}
        </div>
      </main>
      <footer className={'text-right'}>
        <Button
          onClick={() => {
            modalProps.closeModal();
            if (watchProxy) {
              setValue('proxyEntity', null);
            }
          }}
          className={'size-auto-lg'}
        >
          Valider
        </Button>
      </footer>
    </ModalComponent>
  );
};

export default ModalProxy;
