import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import GenericPageWrapper from 'components/styled/wrappers/GenericPageWrapper';
import { BlockSection } from 'components/styled/blocks/BlockSection';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import CircleNumber from 'components/styled/atoms/CircleNumber';
import PageTitle from 'components/commun/PageTitle';
import { documents } from 'constants/documentsFolder';
import useGetDocumentsByType from 'hooks/useGetDocumentsByType';
import DocumentDisplay from 'components/commun/documentDisplay';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import { Loader } from 'components/loader';

const FilesPage = () => {
  const { type, id } = useParams();
  const { state } = useLocation();
  const [data, isLoading] = useGetDocumentsByType(type, id);

  if (isLoading) return <Loader />;

  return (
    <GenericPageWrapper className='files-page row'>
      <nav className='files-count-zone generic-page-wrapper__view-zone d-flex justify-content-center d-lg-none align-items-center'>
        <CircleNumber>{data?.total} </CircleNumber> Files
      </nav>

      <BlockSection className='block-section block-section-files'>
        <MainSubContainer className='row'>
          <PageTitle mainText={'Fichiers'} hasBackTo={'/'} subText={state?.joName} />
          <div className='container-lg block-section__container'>
            <HeadlineStyled3 $weight={'bold'} className={'mb-5'}>
              {documents?.find((doc) => doc.type === type)?.title}
            </HeadlineStyled3>

            {data?.results.length > 0 ? (
              <div className='row'>
                {data?.results?.map((doc) => (
                  <div className={'col-lg-6 mb-3'} key={doc.id}>
                    <DocumentDisplay id={doc.id} name={doc.name} />
                  </div>
                ))}
              </div>
            ) : (
              <p>Cette rubrique ne contient pas de document.</p>
            )}
          </div>
        </MainSubContainer>
      </BlockSection>
    </GenericPageWrapper>
  );
};

export default FilesPage;
