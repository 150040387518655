import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Card, { CardInner } from 'components/styled/wrappers/Card';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import { Button } from 'components/styled/buttons/ButtonStyled';
import TextArea from 'components/styled/forms/TextArea';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import {
  useAddMeetingItemCommentMutation,
  useDeleteMeetingItemCommentMutation,
  useGetMeetingItemCommentsQuery,
  useUpdateMeetingItemCommentMutation
} from 'api/services/meetingApi';
import styled from 'styled-components';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import AppIcon from 'components/icons/AppIcon';
import { Loader } from 'components/loader';
import { useNavigate, useParams } from 'react-router-dom';

const MeetingItemComment = ({ item, participantId, meetingStatus }) => {
  const history = useNavigate();
  const { id: meetingId } = useParams();

  const { data: commentData, isLoading } = useGetMeetingItemCommentsQuery({
    item: item.id,
    participant: participantId
  });

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      comment: '',
      id: null
    }
  });

  const [addMeetingItemComment, { data: successAddData, isSuccess: isSuccessAdd, isError: isErrorAdd }] =
    useAddMeetingItemCommentMutation();
  const [deleteMeetingItemComment, { isSuccess: isSuccessDelete, isError: isErrorDelete }] =
    useDeleteMeetingItemCommentMutation();
  const [updateMeetingItemComment, { data: updatedComment, isSuccess: isSuccessUpdate, isError: isErrorUpdate }] =
    useUpdateMeetingItemCommentMutation();

  useEffect(() => {
    if (isSuccessAdd) {
      toast('Votre commentaire a bien été ajouté', {
        type: 'success'
      });
      reset({
        id: successAddData.id,
        comment: successAddData.comment
      });
    }
    if (isSuccessUpdate) {
      toast('Votre commentaire a bien été mis a jour', {
        type: 'success'
      });
      reset({
        id: updatedComment.id,
        comment: updatedComment.comment
      });
    }
  }, [isSuccessAdd, successAddData, isSuccessUpdate, updatedComment, reset]);

  useEffect(() => {
    if (isSuccessDelete) {
      reset({
        id: null,
        comment: ''
      });
      toast('Votre commentaire a bien été supprimé', {
        type: 'success'
      });
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isErrorUpdate || isErrorAdd) {
      toast("Votre commentaire n'a pu être envoyé", {
        type: 'error'
      });
    }
  }, [isErrorUpdate, isErrorAdd, isErrorDelete]);

  useEffect(() => {
    if (!isLoading && commentData) {
      reset({
        id: commentData[0]?.id ?? null,
        comment: commentData[0]?.comment ?? ''
      });
    }
  }, [isLoading, commentData, reset]);

  const sendData = async (d) => {
    if (d?.id) {
      await updateMeetingItemComment({
        id: d.id,
        data: {
          comment: d.comment
        }
      });
    } else {
      await addMeetingItemComment({
        participant: `/api/v1/meeting_event_participants/${participantId}`,
        item: `/api/v1/meeting_items/${item.id}`,
        comment: d.comment
      });
    }
    history(`/meetings/${meetingId}/items`);
  };

  const deleteComment = async (id) => {
    await deleteMeetingItemComment(id);
    history(`/meetings/${meetingId}/items`);
  };

  if (isLoading) {
    return <Loader width={50} />;
  }

  const commentId = watch('id');
  const commentFormState = watch('comment');

  return (
    <Card>
      <CardInner>
        <MeetingItemCommentStyled>
          <HeadlineStyled3 $weight={'bold'}>Commentaire</HeadlineStyled3>
          {!!commentId && meetingStatus === 3 && (
            <div className={'d-flex justify-content-end'}>
              <button
                type={'button'}
                disabled={commentFormState === ''}
                onClick={() => (commentId ? deleteComment(commentId) : null)}
                className={'delete-comment-btn'}
                data-tip
                data-for='deleter'
              >
                <AppIcon name={'trash'} />
              </button>
              <ReactTooltip id={'deleter'}>
                <span>Supprimer définitivement ce commentaire</span>
              </ReactTooltip>
            </div>
          )}

          {meetingStatus === 3 && (
            <form className={'comment-form'} onSubmit={handleSubmit(sendData)}>
              <Controller
                control={control}
                name={'comment'}
                rules={{
                  required: 'Merci de remplir ce champs',
                  minLength: {
                    value: 1,
                    message: 'Saisir au moins un caractère'
                  },
                  maxLength: {
                    value: 500,
                    message: 'La limite de 500 caractères est atteinte'
                  }
                }}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                  <TextArea
                    onChange={onChange}
                    onBlur={onBlur}
                    entity={'item-comment'}
                    error={error}
                    placeholder={'Ajouter un commentaire, proposer un autre prestataire...'}
                    value={value}
                  />
                )}
              />

              <footer className={'d-flex comment-form__footer'}>
                <Button
                  type={'submit'}
                  className={'comment-submit-btn comment-form__btn'}
                  disabled={commentFormState === ''}
                >
                  Envoyer
                </Button>
              </footer>
            </form>
          )}

          {meetingStatus !== 3 && commentData && (
            <p>{commentData[0]?.comment ?? "Il n'existe pas de commentaire pour cet ordre du jour"}</p>
          )}
        </MeetingItemCommentStyled>
      </CardInner>
    </Card>
  );
};

export default MeetingItemComment;

const MeetingItemCommentStyled = styled('div').attrs({
  className: 'meeting-item-component-styled'
})`
  h3 {
    margin-bottom: 30px;
  }

  .comment-form {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .comment-form__footer {
    margin-top: 40px;
    flex-direction: column;
  }

  .comment-form__btn + .comment-form__btn {
    margin-top: 20px;
  }

  .comment-form__btn {
    width: auto;
  }

  .delete-comment-btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media only screen and ${breakpoint.device.lg} {
    .comment-form__footer {
      flex-direction: row;
      justify-content: end;
    }

    .comment-form__btn + .comment-form__btn {
      margin-top: 0;
      margin-left: 15px;
    }
  }
`;
