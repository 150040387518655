import React from 'react';
import { useGetAllMeetingsQuery } from 'api/services/meetingApi';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import styled from 'styled-components';
import { HeadlineStyled1, HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import MeetingItemComponent from 'pages/dashboard/components/meetingItems/MeetingItemComponent';
import { isAfter, isSameDay } from 'date-fns';
import { Loader } from 'components/loader';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import NoMeetingToCome from 'components/commun/NoMeetingToCome';

const MeetingsWelcome = () => {
  const { data: meetings, isLoading: isLoadingMeetings } = useGetAllMeetingsQuery();

  if (isLoadingMeetings) {
    return <Loader />;
  }

  const isOngoingMeeting = (meeting) =>
    meeting.status_id === 3 &&
    (isAfter(new Date(meeting?.date), new Date()) || isSameDay(new Date(meeting?.date), new Date()));
  const hasOnGoingMeetings = meetings?.some((m) => isOngoingMeeting(m));
  const hasCompletedMeetings = meetings?.some((m) => m.status_id >= 4);

  return (
    <MainSubContainer>
      <MeetingsPageStyled className='meetings-page--styled'>
        <HeadlineStyled1 $weight={'medium'}>Assemblées Générales</HeadlineStyled1>

        <div className='container'>
          <section className={'meetings-section-block meetings-section-block--future-meetings'}>
            <HeadlineStyled3 $weight={'bold'}>Mes prochaines assemblées</HeadlineStyled3>
            {meetings?.length > 0 && hasOnGoingMeetings ? (
              <div className='row meetings-section-block__row'>
                {meetings
                  .filter((meeting) => isOngoingMeeting(meeting))
                  .map((meeting) => {
                    return (
                      <div key={meeting.id} className={'col-lg-6 meetings-section-block__col'}>
                        <MeetingItemComponent meeting={meeting} buttonLabel={'Voir détail'} />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <NoMeetingToCome />
            )}
          </section>

          {hasCompletedMeetings && (
            <section className={'meetings-section-block meetings-section-block--completed-meetings'}>
              <HeadlineStyled3 $weight={700}>Mes précédentes assemblées</HeadlineStyled3>

              {meetings?.length > 0 && (
                <div className='row meetings-section-block__row'>
                  {meetings
                    .filter((meeting) => meeting.status_id >= 4)
                    .map((meeting) => {
                      return (
                        <div key={meeting.id} className={'col-lg-6 meetings-section-block__col'}>
                          <MeetingItemComponent meeting={meeting} buttonLabel={'Voir détail'} />
                        </div>
                      );
                    })}
                </div>
              )}
            </section>
          )}
        </div>
      </MeetingsPageStyled>
    </MainSubContainer>
  );
};

export default MeetingsWelcome;

const MeetingsPageStyled = styled.section`
  padding-top: 30px;

  .headline-1 {
    margin-bottom: 40px;
    text-align: right;
    padding: 0 30px;
    font-size: 1.3rem;
  }

  .meeting-item-styled {
    max-width: 100%;
  }

  .meetings-section-block {
    margin-top: 20px;
    padding-top: 20px;

    .row {
      margin-top: 10px !important;
    }
  }

  @media only screen and ${breakpoint.device.lg} {
    .headline-1 {
      text-align: left;
      font-size: 2.125rem;
      padding: 0;
    }

    .headline-3 {
      margin-bottom: 20px;
    }

    .meetings-section-block__col {
      margin-bottom: 20px;
    }
  }
`;

MeetingsPageStyled.displayName = 'MeetingsPageStyled';
