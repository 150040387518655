import React from 'react';
import Dashboard from 'pages/dashboard/Dashboard';
import Logout from 'pages/logout';
import PageNotFound from 'pages/PageNotFound';
import VotePage from 'pages/vote';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import DesktopNav from './nav/desktopNav';
import MobileNav from 'components/layout/nav/mobileNav';
import StyledGuide from 'pages/StyledGuide';
import AppWrapper from 'components/styled/wrappers/AppWrapper';
import Sidebar from 'components/styled/wrappers/Sidebar';
import Main from 'components/styled/wrappers/Main';
import Header from './Header';
import { useIdleTimer } from 'react-idle-timer';
import { useAuthContext } from 'auth/ProvideAuth';
import FilesPage from 'pages/filesPage';
import MeetingsWelcome from 'pages/meetings';
import MeetingDetail from 'pages/meetings/MeetingDetail';
import MeetingItemsWelcome from 'pages/meetings/MeetingItemsWelcome';
import MeetingItemDetail from 'pages/meetings/components/MeetingItemDetail';
import DocumentsPage from 'pages/documents';

const AuthenticatedApp = () => {
  const timeout = 1000 * 60 * 30;
  const [isTimeOut, setIsTimeOut] = React.useState(false);

  const { signOut } = useAuthContext();

  const handleOnIdle = () => {
    setIsTimeOut(true);
    // TODO: add Modal code
    // openModal({
    //   modalType: 'yesNo',
    //   data: {
    //     title: 'Temps de inactivité',
    //     body: `Vous avez rester 30 min non active vous voulez rester connectez ? ` // TODO: validez le texte !
    //   },
    //   callback: {
    //     save: () => setIsTimeOut(false),
    //     discard: () => signOut()
    //   }
    // });
  };

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    onAction: () => setIsTimeOut(false),
    onActive: () => setIsTimeOut(false),
    crossTab: {
      emitOnAllTabs: true
    },
    debounce: 200
  });

  React.useEffect(() => {
    let timeOutInterval = 0;
    if (isTimeOut) {
      timeOutInterval = setTimeout(() => {
        // TODO: add modal
        signOut();
      }, 5000);
    }

    return () => {
      clearInterval(timeOutInterval);
    };
  }, [isTimeOut /*, closeModal*/, signOut]);
  return (
    <AppWrapper>
      <Router>
        <MobileNav />

        <Sidebar className='col-3 d-none d-lg-block ag-app-sidebar'>
          <DesktopNav />
        </Sidebar>

        <Main className='container-fluid position-relative'>
          <Header />
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/residences/:id/files/:type' element={<FilesPage />} />
            <Route path='/meetings/:id/detail' element={<MeetingDetail />} />
            <Route path='/meetings/:id/vote' element={<VotePage />} />
            <Route path='/meetings/:id/items/:itemId' element={<MeetingItemDetail />} />
            <Route path='/meetings/:id/items' element={<MeetingItemsWelcome />} />
            <Route path='/meetings' element={<MeetingsWelcome />} />
            <Route path='/documents' element={<DocumentsPage />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/styled' element={<StyledGuide />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Main>
      </Router>
    </AppWrapper>
  );
};

export default AuthenticatedApp;
