import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './modals.css';
import AppIcon from '../icons/AppIcon';

const ModalBase = ({
  isOpen,
  closeModal,
  isLocked,
  children,
  customStyle = {},
  closeTimeoutMS = 200,
  showCloseBtn = true,
  otherClass = '',
  overlayClass = ''
}) => {
  const style = {
    overlay: customStyle.overlay,
    content: customStyle.content
  };

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  return (
    <Modal
      closeTimeoutMS={closeTimeoutMS}
      style={style}
      isOpen={isOpen}
      onRequestClose={isLocked ? null : closeModal}
      className={`Modal ${otherClass} `}
      overlayClassName={`Overlay ${overlayClass}`}
    >
      {/*<div className={`modal-inner-wrapper${otherClass ? ` ${otherClass}` : ''}`}>*/}
      <div className='modal-inner-wrapper'>
        {showCloseBtn && (
          <button onClick={closeModal} className='close-modal-btn'>
            <AppIcon name='cross-field' />
          </button>
        )}
        {children}
      </div>
    </Modal>
  );
};

export default ModalBase;
