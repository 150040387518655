import React from 'react';
import { useGetMeetingByIdQuery, useGetMeetingItemByIdQuery } from 'api/services/meetingApi';
import RenderType from './voteTypes';
import { useParams } from 'react-router';
import { Loader } from 'components/loader';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import PageTitle from 'components/commun/PageTitle';
import { IconLabelButtonStyled } from 'components/styled/buttons/ButtonStyled';

const VotePage = () => {
  const { id: eventId } = useParams();
  const [currentItemId, setCurrentItemId] = React.useState(null);
  const [step, setSteps] = React.useState(null);
  const [reRender, setReRender] = React.useState(true);
  const { data, isLoading, error } = useGetMeetingByIdQuery(eventId);
  const { data: itemData, isLoading: isLoadingItem } = useGetMeetingItemByIdQuery(currentItemId, {
    skip: !currentItemId
  });

  React.useEffect(() => {
    if (!isLoading) {
      if (step <= data?.items?.length) setCurrentItemId(data?.items?.find((item) => item.position === step)?.id);
    }
  }, [isLoading, step]);

  const goNextItem = () => {
    setReRender(false);
    setSteps(step + 1);
    setTimeout(() => {
      setReRender(true);
    }, 1000);
  };

  const goPreviousItem = () => {
    setReRender(false);
    setSteps(step - 1);
    setTimeout(() => {
      setReRender(true);
    }, 1000);
  };

  React.useEffect(() => {
    if (!isLoading) {
      if (!data?.timeStart || data?.timeEnd) {
        setSteps(3);
      } else {
        setSteps(1);
      }
    }
  }, [isLoading]);

  if (isLoading || isLoadingItem || !step || !reRender) {
    return <Loader />;
  }

  if (error) {
    return <h1>Vous n'avez pas le droit d'acceder à cette AG</h1>;
  }

  return (
    <MainSubContainer className={`row vote-step-${step}`}>
      <div className='row d-none d-lg-block g-0'>
        <PageTitle
          jointOwnership={data?.jointOwnership}
          subText={'Vote'}
          meeting={data}
          hasBackTo={`/meetings/${data?.id}/detail`}
        />
      </div>
      <div className='g-0'>
        <div className='render-type-zone'>
          {reRender && (
            <RenderType
              step={step}
              item={itemData}
              event={data?.event}
              totalSteps={data.items.length}
              setStep={setSteps}
              secretaryNbr={data?.jointOwnership?.secretaryNbr}
              proxiedParticipants={
                data?.event?.currentParticipant !== undefined
                  ? [
                      ...data?.event?.currentParticipant?.proxiedParticipants?.map((p) => p.id),
                      data?.event?.currentParticipant?.id
                    ]
                  : []
              }
              currentParticipant={data?.event?.currentParticipant?.id}
              isOnGoing={!!data?.timeStart}
              itIsEnd={!!data?.timeEnd}
              zoomId={data?.event?.zoomId}
              mdp={data?.jointOwnership?.code}
              meetingStatus={data?.status}
            />
          )}
        </div>
      </div>

      {!isLoadingItem && (
        <div className='prevNextStep'>
          {data.items.length >= step && step > 1 && (data?.timeStart || step > 3) && (!data.timeEnd || step > 3) && (
            <IconLabelButtonStyled
              iconName={'arrow-vector'}
              dynamicText={'Précédent'}
              className='prevNextStep-btn prev-step-btn mt-5 mb-5'
              handleOnClick={goPreviousItem}
              iconDirection={'left'}
              hasBgColor
              withTransition
            />
          )}

          {data.items.length >= step && step !== 1 && (
            <IconLabelButtonStyled
              iconName={'arrow-vector'}
              dynamicText={'Suivant'}
              className='prevNextStep-btn next-step-btn mt-5 mb-5'
              handleOnClick={goNextItem}
              hasBgColor
              reversed
              withTransition
            />
          )}
        </div>
      )}
    </MainSubContainer>
  );
};

export default VotePage;
