import { OWNERS } from 'constants/api.constants';
import { apiCreator } from '../baseApi';

const endPoints = (builder) => ({
  getAllOwners: builder.query({
    query: ({ page = 1, perPage = 3000, status, joint_ownership, name, type }) => ({
      params: {
        perPage,
        page,
        status,
        joint_ownership,
        name,
        type
      },
      url: OWNERS
    })
  })
});

export const ownersApi = apiCreator({
  reducerPath: 'ownersApi',
  endPointsConfigFn: endPoints
});

export const { useGetAllOwnersQuery } = ownersApi;
