import { useState } from 'react';
import { useGetAllOwnersQuery } from 'api/services/ownersApi';

const useGetOwnerBundles = (joId) => {
  const [ownerFilter, setOwnerFilter] = useState({
    page: 1,
    perPage: 3000,
    joint_ownership: joId,
    name: null
  });

  const { data, isLoading } = useGetAllOwnersQuery(ownerFilter, {
    skip: !joId
  });
  const user = JSON.parse(sessionStorage.getItem('user'));

  const ownerData = data?.results?.find((result) => result.id === user?.ownerId);
  if (ownerData) {
    return {
      ownerships: ownerData.ownerships[joId],
      isLoading
    };
  }
  return {
    ownerships: {},
    isLoading
  };
};

export default useGetOwnerBundles;
