import { apiCreator } from 'api/baseApi';
import {
  MEETING_EVENT_PARTICIPANTS,
  MEETING_INVITATIONS_BY_ID,
  MEETING_EVENT_PARTICIPANTS_LIST
} from 'constants/api.constants';

const endPoints = (build) => ({
  //MEETING_EVENT_PARTICIPANTS
  signeForEvent: build.mutation({
    query: ({ id, ...patch }) => ({
      url: MEETING_EVENT_PARTICIPANTS(id),
      method: 'PATCH',
      data: patch
    })
  }),
  confirmConvocation: build.mutation({
    query: ({ id, data }) => ({
      url: MEETING_INVITATIONS_BY_ID(id),
      method: 'PATCH',
      data
    })
  }),
  getListOfParticipant: build.query({
    query: ({ event, idParticipant, page, perPage, owner, syndic, isPrimaryContact = 1, header = true, presence }) => ({
      params: {
        event,
        page,
        perPage,
        'or[owner.person.firstname,owner.person.lastname,owner.company.name]': owner,
        syndic,
        isPrimaryContact,
        'id[]': idParticipant,
        presence
      },
      url: MEETING_EVENT_PARTICIPANTS_LIST,
      header
    })
  })
});

export const meetingEventParticipantApi = apiCreator({
  reducerPath: 'meetingEventParticipantApi',
  endPointsConfigFn: endPoints
});

export const { useSigneForEventMutation, useConfirmConvocationMutation, useGetListOfParticipantQuery } =
  meetingEventParticipantApi;
