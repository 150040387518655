import React, { useState, forwardRef } from 'react';
import { TextFieldStyled } from 'components/styled/forms/TextField';
import TogglePassWrapperStyled from 'components/styled/forms/TogglePassWrapper';
import AppIcon from 'components/icons/AppIcon';
import ErrorText from 'components/styled/forms/ErrorText';
import { getFieldStatus } from 'pages/login/utils/loginHelpers';

const InputTogglePassword = forwardRef(({ errors, password, placeholder }, ref) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <TogglePassWrapperStyled className='pass-wrapper-styled'>
        <TextFieldStyled
          ref={ref}
          type={passwordShown ? 'text' : 'password'}
          className='pass-wrapper__input'
          {...password}
          hasError={errors?.password}
          status={getFieldStatus('password', errors)}
          placeholder={placeholder}
        />
        {!errors?.password && (
          <span className='pass-wrapper__eye' onClick={togglePasswordVisibility}>
            <AppIcon name={passwordShown ? 'eyes' : 'eyes-close'} />
          </span>
        )}
      </TogglePassWrapperStyled>
      {errors?.password && <ErrorText>{errors?.password?.message}</ErrorText>}
    </>
  );
});

export default InputTogglePassword;
InputTogglePassword.displayName = 'InputTogglePassword';
