import React from 'react';
import FolderComponent from 'components/styled/molecules/FolderComponent';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import { BlockSection } from 'components/styled/blocks/BlockSection';

const DocumentFolders = ({ jointOwnerships }) => {
  const doctypes = [
    {
      title: 'Cadastre',
      type: 9
    },
    /*{
      title: 'Certificat syndic',
      type: 48
    },
    {
      title: 'Circulaire',
      type: 21
    },
    {
      title: 'Décompte',
      type: 8
    },*/
    {
      title: 'Passeport énergétique',
      type: 15
    },
    /*    {
      title: 'Photo',
      type: 36
    },*/
    {
      title: 'Procès-verbal',
      type: 11
    },
    {
      title: 'Règlement copropriété',
      type: 12
    }
  ];

  return (
    <BlockSection>
      <MainSubContainer>
        <h3 className='block-section__title'>Mes dossiers</h3>

        {jointOwnerships?.map((jo) => (
          <div className='row' key={`jo-${jo.id}`}>
            {jointOwnerships.length > 1 && <h5 className='block-section__title'>{jo.name}</h5>}
            {doctypes.map((doctype) => (
              <div className='col-6 col-md-3 col-lg-4 col-xl-3 col-xxl-2 mb-4' key={doctype.type}>
                <FolderComponent
                  folderType={doctype.type}
                  folderName={doctype.title}
                  jointOwnershipId={jo.id}
                  joName={jo.name}
                  className={'dashboard-doc-folders'}
                />
              </div>
            ))}
          </div>
        ))}
      </MainSubContainer>
    </BlockSection>
  );
};

export default DocumentFolders;
