import React from 'react';

const genericAddress = (loc, localName) => {
  return (
    <div className={'location-details'}>
      {!!localName && (
        <p>
          <strong>{localName}</strong>
        </p>
      )}
      {loc?.company && <p>{loc?.company?.name}</p>}
      <p>
        <span>{loc?.address?.number?.toLowerCase() !== 'n/a' && loc?.address?.number} </span>
        <span>{loc?.address?.street}, </span>
        <span> {loc?.address?.zip} </span>
      </p>
      <p>
        <span> {loc?.address.city} </span>
      </p>
    </div>
  );
};

const locationFormatter = (meeting = {}) => {
  const { location: loc } = meeting;
  const meetingLocationType = loc?.type.split('/').pop();
  switch (parseInt(meetingLocationType)) {
    case 1:
      return (
        <address className='meeting-info-block__location'>
          {!!meeting?.localName && <p>{meeting?.localName}</p>}
          <p>
            85 rue du Golf, <br /> L-1638 Senningerberg, <br />
            G.-D. Luxembourg
          </p>
        </address>
      );

    case 2:
      return genericAddress(loc?.supplier, meeting?.localName);

    default:
      return genericAddress(loc, meeting?.localName);
  }
};

export default locationFormatter;
