import React from 'react';
import MeetingItemComponent from './MeetingItemComponent';
import MeetingItemsWrapper from 'components/styled/wrappers/MeetingItemsWrapper';
import { isAfter, isSameDay } from 'date-fns';
import NoMeetingToCome from 'components/commun/NoMeetingToCome';

const MeetingItems = ({ meetings }) => {
  const renderMeetings = () => {
    const isOngoingMeeting = (meeting) =>
      meeting.status_id >= 3 &&
      (isAfter(new Date(meeting?.date), new Date()) || isSameDay(new Date(meeting?.date), new Date()));
    const hasOnGoingMeetings = meetings?.some((m) => isOngoingMeeting(m));

    if (meetings?.length === 0 || !hasOnGoingMeetings) {
      return <NoMeetingToCome />;
    }

    return (
      <ul className='meetings-list'>
        {meetings
          .filter((meeting) => isOngoingMeeting(meeting))
          .map((meeting) => {
            return (
              <li key={meeting.id} className='meetings-list__item'>
                <MeetingItemComponent meeting={meeting} />
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <MeetingItemsWrapper>
      <h3 className='block-section__title'>Mes prochaines assemblées</h3>
      {renderMeetings()}
    </MeetingItemsWrapper>
  );
};

export default MeetingItems;
