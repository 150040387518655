import styled from 'styled-components';
import breakpoint from '../breakpoints/breakpoints';
import { CSS_GUIDE } from 'components/styled/constants';

const HeaderWrapper = styled.header.attrs({
  className: 'app-header-wrapper'
})`
  display: flex;

  .user-menu {
    top: 15px;
    position: absolute;
    z-index: 1;
    border-radius: ${CSS_GUIDE.borderRadius};

    #svg-logout {
      fill: #ffffff;
      padding: 10px;
      max-width: 40px;
      max-height: 40px;
      background: #006083;
      border-radius: 50px;
      cursor: pointer;
    }

    .styled-card {
      margin: 0;
      padding: 0;
      transition: 0.2s ease-in-out all;
    }

    .button-styled + .button-styled {
      margin-top: 10px;
    }

    &:hover {
      .styled-card {
        box-shadow: 2px 4px 50px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .user-display {
    display: flex;
    cursor: pointer;
    padding: 15px;
    justify-content: space-between;

    .arrow-vector {
      transform: rotate(90deg);
      height: 25px;
      width: 25px;
      margin-left: 25px;
      fill: ${CSS_GUIDE.colors.primary.hex};
      transition: 0.2s ease-in-out;

      &.reverse {
        transform: rotate(-90deg);
      }
    }

    &__label {
      transition: 0.2s ease-in-out;
    }

    &--expanded {
      .user-display__label {
        margin-bottom: 30px;
      }
    }
  }

  .user-menu__content {
    padding: 15px;
  }

  .user-display__initials {
    background-color: #f2f7f9;
    color: ${CSS_GUIDE.colors.primary.hex};
    padding: 10px;
    border-radius: 50%;
    text-transform: capitalize;
    font-weight: 500;
    margin-right: 18px;
  }

  .user-display__name {
    color: #3c3c3c;
  }

  .user-display__label {
    margin-bottom: 0;

    .user-display--expanded & {
      margin-bottom: 30px;
    }
  }

  @media only screen and ${breakpoint.device.lg} {
    //padding-right: calc(60px + 1.9rem);
    padding: 40px calc(60px - 0.75rem) 30px; // we subtract bootstrap's padding
    margin-left: calc(-60px - 0.5rem);
    margin-bottom: -100px; // lifts the page up to occupy the empty space
    .main-sub-container {
      position: relative;
      min-height: 40px;
      padding-right: 40px;
      //padding-right: calc(60px - 1.3rem);
    }

    .user-menu {
      right: 0;
      top: 0;
    }
  }

  @media only screen and ${breakpoint.device.xl} {
    .user-menu {
      right: 50px;
    }
  }
`;

export default HeaderWrapper;
HeaderWrapper.displayName = 'HeaderWrapper';
