import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div>
      <h1>404 - Cette page n'existe pas</h1>
      <Link to='/'>Retour à l'accueil</Link>
    </div>
  );
};

export default PageNotFound;
