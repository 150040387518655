import React from 'react';
import styled from 'styled-components';
import AppIcon from 'components/icons/AppIcon';
import Card from 'components/styled/wrappers/Card';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { Link } from 'react-router-dom';

const FolderComponent = ({ folderType, folderName, jointOwnershipId, joName, className }) => {
  return (
    <FolderStyled className={className}>
      <Link to={`/residences/${jointOwnershipId}/files/${folderType}`} state={{ joName }}>
        <Card>
          <AppIcon name='folder' />
          <p className='folder-name'>{folderName}</p>

          {/*<p className='folder-block__files-count'>
            <span></span> fichiers
          </p>*/}
        </Card>
      </Link>
    </FolderStyled>
  );
};

const FolderStyled = styled.article.attrs({
  className: 'folder-block-styled'
})`
  color: ${CSS_GUIDE.colors.primary.hex};

  .styled-card {
    padding-top: 20px;
    min-height: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
    transition: 0.2s ease-in-out all;
    &:hover {
      box-shadow: 2px 4px 50px rgb(0 0 0 / 10%);
    }
  }

  #svg-folder {
    max-width: 40px;
    max-height: 40px;
    margin-bottom: 10px;
  }

  .folder-name {
    font-weight: 700;
    text-align: center;
  }

  .folder-block__files-count {
    span {
      color: ${CSS_GUIDE.colors.orange.hex};
      font-weight: 700;
    }
  }

  @media only screen and ${breakpoint.device.md} {
    width: 170px;
  }
`;

export default FolderComponent;
FolderStyled.displayName = 'FolderStyled';
