import React from 'react';
import styled from 'styled-components';
import Card from 'components/styled/wrappers/Card';
import AppIcon from 'components/icons/AppIcon';
import { CSS_GUIDE } from 'components/styled/constants';
import { setCheckThinIcon } from 'components/styled/utils/styled.helpers';
import { Link, useNavigate } from 'react-router-dom';

const MeetingItemArticle = ({ item, meetingData }) => {
  const history = useNavigate();
  const participantId = meetingData?.event?.currentParticipant?.id;
  const hasComment =
    item?.meetingItemComments?.length > 0 &&
    item?.meetingItemComments?.some((comment) => comment.participant.id === participantId);
  return (
    <Card>
      <MeetingItemWelcomeStyled className={'meeting-item-welcome'}>
        <span className={'item-number'}>{item?.position - 1}</span>
        <p className={'item-title'}>
          <Link to={`/meetings/${meetingData?.id}/items/${item?.id}`}>
            {item?.translations[meetingData?.mainLanguage]?.title}
          </Link>
        </p>
        <button className={'item-comment-button'}>
          <AppIcon name={'comment'} onClick={() => history(`/meetings/${meetingData?.id}/items/${item?.id}`)} />
          {hasComment && <span className={'is-commented'} />}
        </button>
      </MeetingItemWelcomeStyled>
    </Card>
  );
};

export default MeetingItemArticle;

const MeetingItemWelcomeStyled = styled.article`
  padding-left: 20px;
  display: flex;
  align-items: center;

  .item-title {
    margin: 0 0 0 30px;

    a {
      color: inherit;
      font-size: 1rem;
      line-height: 22px;
      transition: 0.3s ease-in-out color;

      &:hover {
        color: ${CSS_GUIDE.colors.primary.hex};
      }
    }
  }

  .item-comment-button {
    margin-left: auto;
    flex-shrink: 0;
    position: relative;
  }

  .is-commented {
    display: inline-block;
    width: 27px;
    height: 27px;
    position: absolute;
    top: -10px;
    right: -5px;
    border-radius: 50px;
    background: ${CSS_GUIDE.colors.success} ${setCheckThinIcon('FFFFFF')} no-repeat center;
  }

  .item-number {
    font-size: 2.125rem;
    font-weight: bold;
    color: ${CSS_GUIDE.colors.primary.hex};
  }
`;
