import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputTogglePassword from 'components/forms/InputTogglePassword';
import { ButtonSubmit } from 'components/styled/buttons/ButtonStyled';
import ErrorText from 'components/styled/forms/ErrorText';
import { getFieldStatus } from 'pages/login/utils/loginHelpers';
import { useUserResetConfirmMutation, useUserUpdatePasswordMutation } from 'api/auth/authApi';
import { Loader } from 'components/loader';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Veuillez saisir un mot de passe')
    .matches(/^.*[A-Z]/, 'Il faut au moins une majuscule')
    .matches(/^.*[a-z]/, 'Il faut au moins une minuscule')
    .matches(/^.*[0-9]/, 'Il faut au moins un chiffre')
    .matches(/^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/, 'Il faut au moins un caractère spécial')
    .min(8, 'Il faut au moins 8 caractères'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Les mots de passe ne sont pas les mêmes')
});

const PasswordResetForm = ({ isUpdateForm = false, callBack = null }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, /*isValid,*/ touchedFields, dirtyFields }
  } = useForm({
    defaultValues: {
      password: '',
      passwordConfirmation: ''
    },
    resolver: yupResolver(schema)
  });

  const { state } = useLocation();
  const history = useNavigate();
  const { userToken } = useParams();

  const encryptedData = userToken ? Buffer.from(userToken, 'base64').toString('utf-8').split('@_@') : [];
  const { passwordRef, ...password } = register('password');
  const { passwordConfirmationRef, ...passwordConfirmation } = register('passwordConfirmation');

  const [resetPass, { isSuccess, isLoading, error: errorReset }] = useUserResetConfirmMutation();
  const [updatePass, { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate, error: errorUpdate }] =
    useUserUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      history('/login');
    }

    if (isSuccessUpdate) {
      toast.success('Votre mot de passe a bien été mis à jour');
      if (callBack !== null) {
        callBack();
      } else {
        history('/login');
      }
    }

    if (errorUpdate || errorReset) {
      toast.error("Le lien de réinitialisation de mot de passe n'est plus valide");
    }
  }, [isSuccess, isSuccessUpdate, errorUpdate, errorReset]);

  const onSubmit = (data) => {
    const resetToken = state?.resetToken ?? encryptedData[1];
    if (!resetToken && !isUpdateForm) {
      toast.error("Le lien de réinitialisation de mot de passe n'est plus valide");
    }
    if (isUpdateForm) {
      updatePass({
        id: JSON.parse(sessionStorage.getItem('user'))?.id,
        data: {
          plainPassword: data.password
        }
      });
    } else {
      resetPass({
        email: state?.email ?? encryptedData[0],
        resetToken,
        plainPassword: data.password
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form className='password-reset-page__form' onSubmit={handleSubmit(onSubmit)}>
      <InputTogglePassword
        placeholder='Mot de passe*'
        password={password}
        ref={passwordRef}
        hasError={errors?.password}
        status={getFieldStatus('password', errors, dirtyFields, touchedFields)}
      />
      {errors?.password && <ErrorText>{errors?.password?.message}</ErrorText>}

      <InputTogglePassword
        placeholder='Confirmez le mot de passe*'
        password={passwordConfirmation}
        ref={passwordConfirmationRef}
        hasError={errors?.passwordConfirmation}
        status={getFieldStatus('passwordConfirmation', errors, dirtyFields, touchedFields)}
      />
      {errors?.passwordConfirmation && <ErrorText>{errors?.passwordConfirmation?.message}</ErrorText>}

      <ButtonSubmit large disabled={isLoading || isLoadingUpdate}>
        Mettre à jour le mot de passe
      </ButtonSubmit>
    </form>
  );
};

export default PasswordResetForm;
