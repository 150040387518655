import React from 'react';
import AuthenticatedApp from './authonticatedApp';
import UnauthenticatedApp from './unauthenticatedApp';
import { useAuthContext } from 'auth/ProvideAuth';

const AppLayout = () => {
    const { authToken, hasRoles } = useAuthContext();

    if (authToken && hasRoles) return <AuthenticatedApp />;

    return <UnauthenticatedApp />;
};

export default AppLayout;
