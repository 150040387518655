import React from 'react';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import Card, { CardInner } from 'components/styled/wrappers/Card';
import MeetingActionBlock from 'pages/meetings/components/MeetingActionBlock';
import styled from 'styled-components';
import ParticipationForm from 'pages/dashboard/components/meetingItems/ParticipationForm';
import MeetingPageWrapper from 'pages/meetings/MeetingPageWrapper';
import { dateFormatter } from 'utils/date';
import MeetingInfoMonoBlock from 'pages/meetings/components/MeetingInfoMonoBlock';
import { MEETING_PV_BY_MEETING_ID } from 'constants/api.constants';
import DownloadButton from 'components/styled/buttons/DownloadButton';
import { downloadAnyDocument } from 'api/fileManagementDownload';
import MeetingDocsList from 'pages/meetings/components/MeetingDocsList';
import { ReactComponent as Discussions } from 'assets/images/discussions.svg';
import breakpoints from 'components/styled/breakpoints/breakpoints';

const generatePVFilename = (meetingData) => {
  const code = meetingData?.jointOwnership?.code;
  const meetingDate = dateFormatter(meetingData?.date, 'api_dot');
  const number = `${meetingData?.jointOwnership?.pvNbr}`.padStart(3, '0');
  const pvDate = dateFormatter(meetingData?.pvDate || new Date().toISOString(), 'api_dot');

  return `${code}-${pvDate}-A&A-PV${number}-${meetingDate}.pdf`;
};

const MeetingDetail = () => {
  const messageOnGoing =
    'Prenez connaissance dès maintenant de l’ordre du jour, adressez un commentaire à destination du syndic ou suggérez une nouvelle résolution.';
  const messageCompleted = 'Consultez l’ordre du jour pour cette assemblée générale';

  const voteMessageOngoing =
    'Nous vous invitons à voter sur les différents points de l’ordre du jour. Après votre vote, veuillez bien confirmer et signer, afin que vos votes soient bien pris en considération.';
  const voteMessageCompleted = 'Vous pouvez consulter les résultats des votes pour cette Assemblée Générale';

  return (
    <MeetingPageWrapper hasBackTo={'/meetings'} showMode>
      {(meetingData) => {
        const isMeetingOngoing = meetingData?.status?.id === 3;
        const showPV = meetingData?.status?.id > 4 && meetingData?.status?.id !== 6 // target statuses => 5,7,8
        const showSvgPlaceholder = meetingData?.mode === 3 || meetingData?.status?.id === 4 || meetingData?.status?.id === 6;

        return (
          <MeetingDetailStyled>
            <main className={'meeting-detail__main'}>
              <div className='row meeting-detail__row meeting-detail__row--top'>
                <div className={'col-lg-6'}>
                  <MeetingInfoMonoBlock
                    date={`${dateFormatter(meetingData?.date, 'full')} à ${dateFormatter(meetingData?.date, 'hour')}`}
                    location={meetingData?.location}
                    meeting={meetingData}
                  />
                </div>
                <div className={'col-lg-6'}>
                  <Card>
                    <CardInner $soft>
                      <MeetingDocsList meeting={meetingData} />
                    </CardInner>
                  </Card>
                </div>
              </div>

              <div className='row meeting-detail__row meeting-detail__row--bottom'>
                <div className='col-xl-8'>
                  <MeetingActionBlock
                    title={'Ordre du jour'}
                    linkTo={`/meetings/${meetingData?.id}/items`}
                    text={isMeetingOngoing ? messageOnGoing : messageCompleted}
                    meetingStatus={meetingData?.status?.id}
                    isBtnLight
                  />

                  <MeetingActionBlock
                    title={isMeetingOngoing ? 'Vote par correspondance' : 'Résultats des votes'}
                    iconName={'vote-box'}
                    linkTo={`/meetings/${meetingData?.id}/vote`}
                    meetingStatus={meetingData?.status?.id}
                    text={isMeetingOngoing ? voteMessageOngoing : voteMessageCompleted}
                  />
                </div>

                <div className='col-xl-4'>
                  <Card className={'participation-card'}>
                    {showSvgPlaceholder ? (
                      <div className='participation-empty-space'>
                        <Discussions />
                      </div>
                    ) : (
                      <>
                        {isMeetingOngoing && (
                          <CardInner $soft>
                            <HeadlineStyled3 $weight={'bold'}>Serez-vous présent?</HeadlineStyled3>
                            <ParticipationForm meeting={meetingData} />
                          </CardInner>
                        )}

                        {showPV && (
                          <CardInner $soft>
                            <HeadlineStyled3 $weight={'bold'}>Procès-verbal</HeadlineStyled3>
                            <DownloadButton
                              onClick={() => {
                                downloadAnyDocument({
                                  url: MEETING_PV_BY_MEETING_ID(meetingData?.id),
                                  data: {
                                    action: 'preview'
                                  },
                                  method: 'POST',
                                  fileName: generatePVFilename(meetingData)
                                });
                              }}
                            >
                              <span>Procès-verbal</span>
                            </DownloadButton>
                          </CardInner>
                        )}
                      </>
                    )}
                  </Card>
                </div>
              </div>
            </main>
          </MeetingDetailStyled>
        );
      }}
    </MeetingPageWrapper>
  );
};

export default MeetingDetail;

const MeetingDetailStyled = styled.div`
  .meeting-detail__header {
    text-align: center;
    margin-bottom: 30px;
  }

  .participation-card {
    .headline-3 {
      margin-bottom: 40px;
    }
  }

  .meeting-detail__row--top {
    .styled-card {
      height: calc(100% - 40px); // keeps the the cards at same height
    }
  }

  .meeting-detail__doc-list {
    margin-top: 1.5rem;
  }

  .meeting-detail__doc-list-item {
    margin-bottom: calc(1.5rem * 0.5);
    padding-right: 0;
  }

  .participation-empty-space {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and ${breakpoints.device.xl} {
    .participation-empty-space {
      min-height: 400px;
    }
  }
`;
