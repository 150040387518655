import React from 'react';
import styled from 'styled-components';
import useGetDocumentsByType from 'hooks/useGetDocumentsByType';
import DocumentDisplay from 'components/commun/documentDisplay';
import MeetingItemBlock from 'pages/meetings/components/MeetingItemBlock';
import PageTitle from 'components/commun/PageTitle';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import { Loader } from 'components/loader';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';

const DocumentsPage = () => {
  const currentUser = JSON.parse(sessionStorage.getItem('user'));
  const jointOwnershipObj = JSON.parse(sessionStorage.getItem('jointOwnerships'))?.find((jo) =>
    jo.user_ids.includes(currentUser.id)
  );

  const passEnergeticType = 15;
  const pvType = 11;
  const reglementType = 12;
  const budgetType = 29;

  const [passEnergeticDocuments, isLoadingEnergyDoc] = useGetDocumentsByType(
    passEnergeticType,
    jointOwnershipObj?.joint_ownership_id
  );
  const [pvDocuments, isLoadingPvDoc] = useGetDocumentsByType(pvType, jointOwnershipObj?.joint_ownership_id);
  const [reglementDocuments, isLoadingReglementDoc] = useGetDocumentsByType(
    reglementType,
    jointOwnershipObj?.joint_ownership_id
  );
  const [budgetDocuments, isLoadingBudgetDoc] = useGetDocumentsByType(
    budgetType,
    jointOwnershipObj?.joint_ownership_id
  );

  const DocList = ({ docArray, type }) => {
    const labelObj = {
      11: 'Procès-verbaux',
      12: 'Règlement de copropriété',
      15: 'Passeport Energétique',
      29: 'Budget'
    };
    return (
      docArray?.results?.length > 0 && (
        <div className='row'>
          <HeadlineStyled3 $weight={'bold'}>{labelObj[type]}</HeadlineStyled3>
          {docArray?.results?.map((doc) => (
            <div className={'col-lg-6 mb-3'} key={doc.id}>
              <DocumentDisplay id={doc.id} name={doc.name} />
            </div>
          ))}
        </div>
      )
    );
  };

  if (isLoadingPvDoc || isLoadingEnergyDoc || isLoadingReglementDoc || isLoadingBudgetDoc) {
    return <Loader />;
  }

  return (
    <DocumentsWrapper className={'container-fluid'}>
      <MainSubContainer>
        <PageTitle mainText={'Documents'} className={'documents-page__title'} />

        {passEnergeticDocuments?.results?.length > 0 ||
        pvDocuments?.results?.length > 0 ||
        budgetDocuments?.results?.length > 0 ||
        reglementDocuments?.results?.length > 0 ? (
          <MeetingItemBlock
            mainTitle={`Copropriété ${jointOwnershipObj?.joint_ownership_name}`}
            showBottom
            hideCircle
            showMobile
          >
            <div className='container'>
              <DocList docArray={passEnergeticDocuments} type={passEnergeticType} />
              <DocList docArray={pvDocuments} type={pvType} />
              <DocList docArray={reglementDocuments} type={reglementType} />
              <DocList docArray={budgetDocuments} type={budgetType} />
            </div>
          </MeetingItemBlock>
        ) : (
          <p>Cette section ne contient pas de document.</p>
        )}
      </MainSubContainer>
    </DocumentsWrapper>
  );
};

export default DocumentsPage;

const DocumentsWrapper = styled.section`
  .common-page-header--styled {
    padding-top: 30px;
  }
`;
