import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import AppIcon from 'components/icons/AppIcon';

const ButtonBase = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  border-radius: 100px;
  height: ${({ large }) => (large ? '50px' : '40px')};
  border: 0;
  color: #ffffff;
  width: 100%;

  @media only screen and ${breakpoint.device.lg} {
    &.size-auto-lg {
      width: auto;
      padding: 16px 80px;
    }
`;

export const Button = styled(ButtonBase).attrs({
  className: 'button-styled'
})`
  background: ${({ $positive }) => ($positive ? CSS_GUIDE.colors.success : CSS_GUIDE.colors.secondary.hex)};

  &:hover {
    background: ${({ $positive }) =>
      $positive ? lighten(0.1, CSS_GUIDE.colors.success) : lighten(0.1, CSS_GUIDE.colors.secondary.hex)};
  }

  ${({ disabled }) => {
    if (disabled) {
      return {
        cursor: 'not-allowed',
        opacity: 0.5
      };
    }
  }}
`;

export const ButtonLight = styled(ButtonBase)`
  background: transparent;
  border: 1px solid ${CSS_GUIDE.colors.secondary.hex};
  color: ${CSS_GUIDE.colors.secondary.hex};
`;

export const ButtonSubmit = styled(Button).attrs({
  type: 'submit'
})`
  margin-top: ${CSS_GUIDE.space.vertical.input}px;
`;

export const LinkButtonStyled = styled(Button).attrs({
  as: Link,
  className: 'link-styled'
})`
  color: #ffffff;
  text-decoration: none;
  font-size: inherit;

  &.size-auto-lg {
    width: fit-content;
    margin: auto;
  }

  ${({ disabled }) => {
    if (disabled)
      return {
        'pointer-events': 'none',
        background: CSS_GUIDE.colors.midGrey2.hex
      };
  }}

  ${({ $light }) => {
    if ($light) {
      return {
        background: 'transparent',
        border: `1px solid ${CSS_GUIDE.colors.secondary.hex};`,
        color: CSS_GUIDE.colors.primary.hex,
        transition: 'all 0.1s ease-in-out',
        '&:hover': {
          background: CSS_GUIDE.colors.secondary.hex,
          color: CSS_GUIDE.colors.white.hex
        }
      };
    }
  }}
`;

const IconLabelButton = ({ iconName = 'add', label, handleOnClick = null, className, dynamicText }) => {
  return (
    <button className={className} type={'button'} onClick={(event) => handleOnClick(event)}>
      <AppIcon name={iconName} classes={iconName} />
      <div>
        <span>{label}</span>
        {dynamicText && <span className={'icon-label-button__dynamic-text'}> {dynamicText}</span>}
      </div>
    </button>
  );
};

export const IconLabelButtonStyled = styled(IconLabelButton).attrs({
  className: 'icon-label-button--styled'
})`
  border: none;
  outline: none;
  background-color: ${(props) => (props.hasBgColor ? CSS_GUIDE.colors.primary.rgba : 'transparent')};
  border-radius: ${(props) => (props.hasBgColor ? '50px' : 0)};
  display: flex;
  flex-direction: ${(props) => (props?.reversed ? 'row-reverse' : 'row')};
  justify-content: ${(props) => (props?.reversed ? 'end' : 'flex-start')};
  align-items: center;
  width: 100%;
  height: 40px;
  padding: ${(props) => (props?.reversed ? '0 15px 0 30px ' : '0 30px 0 15px')};

  .prevNextStep & {
    width: 46%;
  }

  .aea-svg {
    ${({ reversed }) => (reversed ? 'margin-left: 10px;' : 'margin-right: 10px;')}
    width: 24px;
    height: 24px;
    position: relative;
    left: 0;
    ${({ withTransition }) => {
      if (withTransition) return 'transition: 0.1s ease-in-out all;';
    }}

    fill: ${(props) => (props?.iconColor ? props.iconColor : CSS_GUIDE.colors.primary.hex)};
    transform: ${(props) => (props?.iconDirection && props.iconDirection === 'left' ? 'rotate(180deg)' : 'rotate(0)')};
  }

  &:hover {
    .aea-svg.arrow-vector {
      left: ${(props) => (props?.reversed ? '3px' : '-3px')};
    }
  }
}

.icon-label-button__dynamic-text {
  margin-left: 10px;
  display: block;
}

@media only screen and ${breakpoint.device.lg} {
  .prevNextStep &, & {
    width: auto;
    justify-content: center;
  }
`;

export const ButtonLink = styled(ButtonBase).attrs({
  className: 'button-link-styled',
  as: 'a'
})`
  min-height: 50px;
  background: ${CSS_GUIDE.colors.secondary.hex};

  &:hover {
    background: ${lighten(0.1, CSS_GUIDE.colors.secondary.hex)};
    color: #ffffff;
  }
`;
