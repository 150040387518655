import React from 'react';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import { useGetMeetingByIdQuery } from 'api/services/meetingApi';
import { useParams } from 'react-router-dom';
import PageTitle from 'components/commun/PageTitle';
import { Loader } from 'components/loader';

const MeetingPageWrapper = ({ pageTitleOtherText, hasBackTo, children, subText, showMode }) => {
  const { id: meetingId } = useParams();
  const { data: meetingData, isLoading } = useGetMeetingByIdQuery(meetingId);

  if (isLoading) {
    return <Loader />;
  }

  const statusText = {
    2: 'AG en cours de préparation',
    3: 'AG convoquée',
    4: 'AG levée',
    5: 'Litige en cours',
    7: 'AG clôturée'
  };

  return (
    <MainSubContainer>
      <PageTitle
        meeting={meetingData}
        otherText={pageTitleOtherText}
        hasBackTo={hasBackTo}
        subText={subText ?? statusText[meetingData?.status?.id] ?? null}
        showMode={showMode}
      />
      {children(meetingData)}
    </MainSubContainer>
  );
};

export default MeetingPageWrapper;
