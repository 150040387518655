import styled from 'styled-components';
import { TextFieldBase } from 'components/styled/forms/TextField';

const encodedSearchIcon = `url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.2083 12.8333H13.4842L13.2275 12.5858C14.1258 11.5408 14.6667 10.1842 14.6667 8.70833C14.6667 5.4175 11.9992 2.75 8.70833 2.75C5.4175 2.75 2.75 5.4175 2.75 8.70833C2.75 11.9992 5.4175 14.6667 8.70833 14.6667C10.1842 14.6667 11.5408 14.1258 12.5858 13.2275L12.8333 13.4842V14.2083L17.4167 18.7825L18.7825 17.4167L14.2083 12.8333ZM8.70833 12.8333C6.42583 12.8333 4.58333 10.9908 4.58333 8.70833C4.58333 6.42583 6.42583 4.58333 8.70833 4.58333C10.9908 4.58333 12.8333 6.42583 12.8333 8.70833C12.8333 10.9908 10.9908 12.8333 8.70833 12.8333Z' fill='%237C8188'/%3E%3C/svg%3E%0A") `;

// todo : wrap inside a div if need to display the x button
const SearchFieldStyled = styled(TextFieldBase)`
  padding-left: 40px;
  margin-bottom: 20px;
  background: ${encodedSearchIcon} no-repeat 14px calc(50% + 3px) #ffffff;
`;

export default SearchFieldStyled;
SearchFieldStyled.displayName = 'SearchFieldStyled';
