import React from 'react';
import Login from 'pages/login/Login';
import PasswordRecovery from 'pages/passwordRecovery/PasswordRecovery';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PasswordReset from 'pages/passwordRecovery/PasswordReset';

const UnauthenticatedApp = () => {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/password-recovery' element={<PasswordRecovery />} />
        <Route path='/password-reset' element={<PasswordReset />} />
        <Route path='/password-reset/:userToken' element={<PasswordReset />} />
        <Route path='*' element={<Navigate to='/login' />} />
      </Routes>
    </Router>
  );
};

export default UnauthenticatedApp;
