import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import MeetingItemArticle from './components/MeetingItemArticle';
import MeetingPageWrapper from './MeetingPageWrapper';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import DocumentDisplay from 'components/commun/documentDisplay';
import { ButtonLink } from 'components/styled/buttons/ButtonStyled';

const MeetingItemsWelcome = () => {
  const { id: meetingId } = useParams();

  return (
    <MeetingPageWrapper pageTitleOtherText={'Ordre du jour'} hasBackTo={`/meetings/${meetingId}/detail`}>
      {(meetingData) => (
        <MeetingItemsWelcomeStyled className={'meeting-detail--styled'}>
          <main className={'meeting-detail__main'}>
            <ul>
              {meetingData.items
                .filter((it) => it.position > 1)
                .sort((f, s) => (f.position > s.position ? 1 : -1))
                .map((item) => (
                  <li key={item.id}>
                    <MeetingItemArticle item={item} meetingData={meetingData} />
                  </li>
                ))}
            </ul>

            {meetingData?.documents?.length > 0 && (
              <div className={'meeting-detail__docs'}>
                <HeadlineStyled3 $weight={'bold'}>Annexes</HeadlineStyled3>
                <ul className='row'>
                  {meetingData?.documents.map((doc) => (
                    <li key={doc.id} className={'col-lg-6 mb-4'}>
                      <DocumentDisplay id={doc.id} name={doc.name} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </main>

          {meetingData?.status?.id === 3 && (
            <footer>
              <ButtonLink
                href={"mailto:aea@aea.lu?subject=Suggérer un point à l'ordre du jour"}
                className={'size-auto-lg'}
              >
                Suggérer un point au Syndic
              </ButtonLink>
            </footer>
          )}
        </MeetingItemsWelcomeStyled>
      )}
    </MeetingPageWrapper>
  );
};

export default MeetingItemsWelcome;

const MeetingItemsWelcomeStyled = styled.div`
  .meeting-detail__main {
    margin-top: 50px;
  }

  .meeting-detail__header {
    text-align: center;
    margin-bottom: 30px;
  }

  .participation-card {
    .headline-3 {
      margin-bottom: 40px;
    }
  }

  .meeting-detail__docs {
    margin-top: 40px;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
`;
