import React, { useRef, useState } from 'react';
import HeaderWrapper from '../styled/wrappers/HeaderWrapper';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import { useAuthContext } from 'auth/ProvideAuth';
import { Button } from 'components/styled/buttons/ButtonStyled';
import AppIcon from 'components/icons/AppIcon';
import Card from 'components/styled/wrappers/Card';
import useModal from 'hooks/useModal';
import useClickOutside from 'hooks/useClickOutside';
import PasswordResetForm from 'pages/passwordRecovery/PasswordResetForm';
import ModalComponent from 'components/modals/ModalComponent';

const Header = () => {
  const { signOut } = useAuthContext();
  const logOut = () => signOut();
  const [isOpen, setIsOpen] = useState(false);
  const { openModal, ...modalProps } = useModal();
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setIsOpen(false));

  return (
    <HeaderWrapper ref={wrapperRef}>
      <MainSubContainer>
        <nav className={`user-menu d-none d-lg-flex ${isOpen ? 'user-menu--is-open' : ''}`}>
          <Card>
            <UserDisplay onSetIsOpen={setIsOpen} isMenuOpen={isOpen} />
            {isOpen && (
              <div className={'user-menu__content'}>
                <Button className='logout-btn' onClick={logOut}>
                  Déconnexion
                </Button>
                <Button onClick={openModal}>Mettre à jour mot de passe</Button>
              </div>
            )}
          </Card>
        </nav>
      </MainSubContainer>
      <ModalComponent modalProps={modalProps} otherClass={'user-preference-modal'}>
        <PasswordResetForm isUpdateForm callBack={modalProps.closeModal} />
      </ModalComponent>
    </HeaderWrapper>
  );
};

export default Header;

const UserDisplay = ({ onSetIsOpen, isMenuOpen }) => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const initials = () => {
    if (user?.firstName && !user?.lastName) {
      return user.firstName.substring(0, 2)?.toUpperCase();
    }

    if (!user?.firstName && user?.lastName) {
      return user.lastName.substring(0, 2)?.toUpperCase();
    }

    return (user.firstName?.[0] + user.lastName?.[0])?.toUpperCase();
  };

  return (
    <div
      className={`user-display ${isMenuOpen ? 'user-display--expanded' : ''}`}
      onClick={() => onSetIsOpen((prev) => !prev)}
    >
      <p className={'user-display__label'}>
        <span className={'user-display__initials'}>{initials()}</span>
        <span className={'user-display__name'}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</span>
      </p>

      <span>
        <AppIcon name={'arrow-vector'} classes={`arrow-vector ${isMenuOpen ? 'reverse' : ''}`} />
      </span>
    </div>
  );
};
