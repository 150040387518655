import React from 'react';
import MeetingItemStyled from 'components/styled/molecules/MeetingItemStyled';
import { PillInfo } from 'components/styled/pills/Pill';
import TextWithIcon from 'components/commun/TextWithIcon';
import { LinkButtonStyled } from 'components/styled/buttons/ButtonStyled';
import { isSameDay, isSameHour } from 'date-fns';
import { dateFormatter, daysHoursReminder } from 'utils/date';

const MeetingItemComponent = ({ meeting }) => {
  const isStart = isSameHour(new Date(meeting?.date), new Date());
  const isToday = isSameDay(new Date(meeting?.date), new Date()) && isStart && meeting?.status_id === 3;
  const isCompleted = meeting?.status_id >= 4;

  const timingStatus = () => {
    if (isCompleted) return meeting?.status_name?.fr;
    return isToday ? meeting?.status_name.fr : daysHoursReminder(meeting?.date, 'dans');
  };

  return (
    <MeetingItemStyled statusId={meeting?.status_id}>
      <div className={'meeting-item-styled__top'}>
        <header>
          <p className='mb-0 stronger-text'>{meeting?.joint_ownership_name}</p>
          <p className='mb-0 meeting-status'>{timingStatus()}</p>
        </header>
        <main className='mb-3'>
          <PillInfo className='meeting-pill'>Assemblée générale {meeting?.type_name?.fr}</PillInfo>
          {meeting?.address_fulltext !== 'inline meeting or null' && (
            <TextWithIcon iconName={'location'} otherClassName={'text-with-location-icon'}>
              {meeting?.local_name && <p className={'mb-0'}>{meeting.local_name}</p>}
              <span>{meeting?.address_fulltext}</span>
            </TextWithIcon>
          )}

          <TextWithIcon iconName={'clock'}>
            {isToday ? 'Maintenant' : dateFormatter(meeting?.date, 'full')}
          </TextWithIcon>
        </main>
      </div>
      <footer>
        <LinkButtonStyled
          to={isToday ? `/meetings/${meeting?.id}/vote` : `/meetings/${meeting?.id}/detail`}
          $statusStyle={meeting?.status_id}
        >
          {isToday ? 'Voter' : 'Voir le détail'}
        </LinkButtonStyled>
      </footer>
    </MeetingItemStyled>
  );
};

export default MeetingItemComponent;
