import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldStyled } from 'components/styled/forms/TextField';
import { ButtonSubmit } from 'components/styled/buttons/ButtonStyled';
import ErrorText from 'components/styled/forms/ErrorText';
import { useUserResetMutation } from 'api/auth/authApi';
import { getFieldStatus } from 'pages/login/utils/loginHelpers';
import { Loader } from 'components/loader';

const schema = yup.object().shape({
  email: yup.string().email().required('Veuillez saisir votre e-mail')
});

const PasswordRecoveryForm = ({ gotToCodeVerification }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, /*isValid,*/ touchedFields, dirtyFields }
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  });

  const [userReset, { isLoading, isSuccess }] = useUserResetMutation();
  useEffect(() => {
    if (isSuccess) {
      gotToCodeVerification(getValues('email'));
    }
  }, [isSuccess, gotToCodeVerification]);

  const onSubmit = (data) => {
    userReset(data);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form className='password-recovery-page__form' onSubmit={handleSubmit(onSubmit)}>
      <TextFieldStyled
        placeholder='E-mail'
        {...register('email')}
        hasError={errors.email}
        status={getFieldStatus('email', errors, dirtyFields, touchedFields)}
      />
      {errors.email && <ErrorText>{errors.email?.message}</ErrorText>}

      <ButtonSubmit large>Réinitialiser</ButtonSubmit>
    </form>
  );
};

export default PasswordRecoveryForm;
