import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { TextFieldInline } from 'components/styled/forms/TextField';
import { ButtonSubmit } from 'components/styled/buttons/ButtonStyled';
import ErrorText from 'components/styled/forms/ErrorText';
import './styles/code-verification.css';
import { useUserResetCheckMutation } from 'api/auth/authApi';

const CodeVerificationForm = ({ userEmail }) => {
  const digits = [...new Array(6).keys()];

  const [userResetCheck, { isSuccess, isError }] = useUserResetCheckMutation();
  const [resetObject, setResetObject] = useState(null);

  const {
    control,
    handleSubmit,
    setFocus,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate('/password-reset', {
        state: resetObject
      });
    }
  }, [isSuccess, isError, resetObject]);

  const handleDigit = (idx) => {
    const nextInput = idx < 6;
    if (nextInput) {
      setFocus(`digit-${idx + 1}`);
    }
  };

  const onSubmit = (data) => {
    const concatValues = Object.values(data).reduce((total, value) => total + String(value));
    const resetData = {
      email: userEmail,
      resetToken: concatValues
    };

    setResetObject(resetData);
    userResetCheck(resetData);
  };

  return (
    <form className='otp-code-form' onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='otp-code-form__fieldSet'>
        {digits.map((digit, index) => {
          const digitName = `digit-${index + 1}`;
          return (
            <Controller
              key={digitName}
              control={control}
              name={digitName}
              rules={{
                required: {
                  value: true,
                  message: 'Veuillez saisir un chiffre'
                },
                validate: (value) => {
                  if (Number.isNaN(value)) {
                    return 'Valeur incorrecte';
                  }
                }
              }}
              render={({ field: { ref, onChange } }) => (
                <TextFieldInline
                  className={`code-digit-input ${digitName}`}
                  maxLength={1}
                  ref={ref}
                  onChange={(e) => {
                    onChange(parseInt(e.target.value));
                    handleDigit(index + 1);
                  }}
                />
              )}
            />
          );
        })}
      </fieldset>
      {errors && Object.keys(errors).length > 0 && <ErrorText withBackground>Formulaire invalide</ErrorText>}
      <ButtonSubmit large>Vérifier le code</ButtonSubmit>
    </form>
  );
};

export default CodeVerificationForm;
